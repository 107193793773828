import React from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'

const TimezoneDatePicker = ({
  showTimeSelect = true,
  timeIntervals = 60,
  customInput,
  selected,
  dateFormat,
  onChange,
  targetTimezone = 'America/New_York',
  ...rest
}) => {
  const getTimezoneOffsetInSeconds = (timestamp, timezone) => {
    const offset = moment.unix(timestamp).tz(timezone).format('Z')
    const [hours, minutes] = offset.split(':')
    return (parseInt(hours) * 3600) + (Math.sign(parseInt(hours)) * parseInt(minutes) * 60)
  }

  const getSelectedDate = () => {
    if (!selected) return null
    
    // React datepicker automatically works using the user's timezone
    // To display the league's timezone, compute the difference and add it to the final date

    const targetOffset = getTimezoneOffsetInSeconds(selected, targetTimezone)
    const userOffset = getTimezoneOffsetInSeconds(selected, moment.tz.guess())
    const difference = targetOffset - userOffset
    
    return moment.unix(selected + difference).toDate()
  }

  const handleDateChange = (date) => {
    if (!date) {
      onChange(null)
      return
    }

    // When changing the date, we want to substract the added difference

    const timestamp = moment(date).unix()
    const targetOffset = getTimezoneOffsetInSeconds(timestamp, targetTimezone)
    const userOffset = getTimezoneOffsetInSeconds(timestamp, moment.tz.guess())
    const difference = targetOffset - userOffset
    
    const finalDate = moment.unix(timestamp - difference).unix()
    onChange(finalDate)
  }

  return (
    <DatePicker
      showTimeSelect={showTimeSelect}
      timeIntervals={timeIntervals}
      customInput={customInput}
      selected={getSelectedDate()}
      dateFormat={dateFormat}
      onChange={handleDateChange}
      {...rest}
    />
  )
}

TimezoneDatePicker.propTypes = {
  showTimeSelect: PropTypes.bool,
  timeIntervals: PropTypes.number,
  customInput: PropTypes.element,
  selected: PropTypes.number,
  dateFormat: PropTypes.string,
  onChange: PropTypes.func,
  targetTimezone: PropTypes.string
}

export default TimezoneDatePicker