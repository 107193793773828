import { ActionTypes } from '../actions'

const {
  SHOW_POPUP,
  ON_CLOSE,
  DO_NOT_SHOW,
} = ActionTypes

const initialState = {
  do_not_show: false,
  show_phase_out_popup: false,
  open_modal: false,
  url: '',
}

const popup = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_POPUP:
      return {
        ...state,
        show_phase_out_popup: action.data.show_phase_out_popup,
        open_modal: action.data.open_modal,
        url: action.data.url,
      }
    case ON_CLOSE:
      return {
        ...state,
        do_not_show: false,
        show_phase_out_popup: false,
        url: '',
      }
    case DO_NOT_SHOW:
      return {
        ...state,
        do_not_show: action.checked,
      }
    default:
      return state
  }
}

export default popup