import React, { useEffect, useState, useRef } from 'react'
import arrowIcon from '../../../../assets/images/widgets/icons/arrow.png'
import infoIcon from '../../../../assets/images/widgets/icons/information.png'
import {useDispatch, useSelector} from 'react-redux'
import OttScreen from '../ott_screen'
import {checkIfMinSlotsReached, checkIfAnySlotsChecked, getMinSlots} from '../selectors'
import { callAPI } from 'Shared/helpers'
import {reinitialise, unselectALL, toggleRefreshStatusBar } from '../actions'
import OttErrorPopup from './ott_error_popup'
import {GlgTooltip} from '../../shared/components'
import OttRefreshStatusBar from './ott_refresh_status_bar'


const filterDataByOwnedMember = (data) => {
  const filteredData = data.reduce((result, item) => {
    const ownedPlayers = item.foursome_players.filter(
      (playerInfo) => playerInfo.owned_by_current_member === true && playerInfo.player !== null,
    )

    if (ownedPlayers.length > 0) {
      ownedPlayers.forEach((playerInfo) => {
        result.push({
          foursome_id: item.foursome_id,
          position: item.foursome_players.indexOf(playerInfo),
        })
      })
    }

    return result
  }, [])

  return filteredData
}

const getCheckedIdsByOwnedMember = (data) => {
  const filteredData = data.reduce((result, item) => {
    const ownedPlayers = item.foursome_players.filter(
      (playerInfo) => playerInfo.owned_by_current_member === true && playerInfo.player !== null && playerInfo.is_checked === true,
    )

    if (ownedPlayers.length > 0) {
      ownedPlayers.forEach((playerInfo) => {
        result.push( playerInfo.player[2],
        )
      })
    }

    return result
  }, [])
  return filteredData
}

const getCheckedNamesByOwnedMember = (data, checkedSlots) => {
  const filteredData = data.reduce((result, item) => {
    const ownedPlayers = item.foursome_players.filter(
      (playerInfo) => playerInfo.owned_by_current_member === true && playerInfo.is_checked && playerInfo.player !== null,
    )

    if (ownedPlayers.length > 0) {
      ownedPlayers.forEach((playerInfo) => {
        if (result.length === checkedSlots.length - 1) {
          result.push(playerInfo.player[0])
        } else {
          result.push(`${playerInfo.player[0]}, `)
        }
      })
    }

    return result
  }, [])
  return filteredData
}

const getPlayerIdsByOwnedMember = (data) => {
  const filteredData = data.reduce((result, item) => {
    const ownedPlayers = item.foursome_players.filter(
      (playerInfo) => playerInfo.owned_by_current_member === true && playerInfo.player !== null,
    )

    if (ownedPlayers.length > 0) {
      ownedPlayers.forEach((playerInfo) => {
        result.push( playerInfo.player[2],
        )
      })
    }

    return result
  }, [])
  return filteredData
}

const OttFooter = () => {
  // Redux State
  const activeScreen = useSelector(state => state.activeScreen)
  const leagueId = useSelector(state => state.leagueId )
  const roundId = useSelector(state => state.roundId )
  const selectedSlots = useSelector(state => state.selectedSlots )
  const currentMemberId = useSelector(state => state.currentMemberId)
  const userId = useSelector(state => state.currentUser)
  const data = useSelector(state => state.data )
  const isMinSlotsReached = useSelector(checkIfMinSlotsReached)
  const atLeastOneSlotSelected = useSelector(state => state.selectedSlots.length > 0)
  const checkedSlots = useSelector(state => state.checkedSlots)
  const registeredMembers = useSelector(state => state.members)
  const bookBeforeRegister = useSelector(state => state.bookBeforeRegister)
  const userInMasterRoster = useSelector(state => state.userInMasterRoster)
  const isPaymentsScenario = useSelector( state => state.isPaymentsScenario)
  const currentUser = useSelector(state => state.currentUser)
  const isLoggedInWithGgid = useSelector( state => state.isLoggedInWithGgid)
  const minMembersReg = useSelector( state => state.minMembersReg)
  const websiteId = useSelector( state => state.websiteId)
  const displayMobileScroller = useSelector( state => state.displayMobileScroller)
  const minSlots = useSelector(getMinSlots)
  const [ isMobile, setIsMobile ] = useState(window.innerWidth <= 800)
  const [ isPopupOpen, setPopupOpen ] = useState(false)
  const [ isBeingRedirected, setIsBeingRedirected ] = useState(false)
  const [ isCancelRedirected, setIsCancelBeingRedirected ] = useState(false)
  const [ isErrorPopupOpen, setErrorPopupOpen ] = useState(false)
  const [ errorPopupMessage, setErrorPopupMessage ] = useState('')
  const anySlotsChecked = useSelector(checkIfAnySlotsChecked)
  const dispatch = useDispatch()
  const footerWrapperRef = useRef(null)
  const [ isOverlayVisible, setIsOverlayVisible ] = useState(true)
  const slotsArray = useSelector(state => state.slotsArray)
  const paymentsData = useSelector(state => state.pluckedPayments)
  const currency = useSelector(state => state.currency)

  useEffect(() => {

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800)
      const mainWidth = window.parent.document.querySelector('#main_content').getBoundingClientRect().width
      const footerElement = window.parent.document.querySelector('.ott-mobile-footer');
      if (footerElement) {
        footerElement.style.width = `${mainWidth}px`
      }
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [ isMobile ])

  const openPopup = (e) => {
    e.preventDefault()
    setPopupOpen(true)
  }

  const closePopup = (e) => {
    e.preventDefault()
    setPopupOpen(false)
  }

  const openErrorPopup = () => {
    setErrorPopupOpen(true)
    reinitialiseState()
  }

  const closeErrorPopup = () => {
    setErrorPopupOpen(false)
  }

  const createErrorPopupMessage = (message) => {
    setErrorPopupMessage(message)
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsOverlayVisible(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const reinitialiseState = () => {
    const url = `/leagues/${leagueId}/widgets/players_choose_tee_times/reinitialise_and_set_current_member_for_admin`
    callAPI(url, 'POST', { round_id: roundId, selected_member_id: currentMemberId })
      .then((resp) => {
        dispatch(reinitialise(resp.message))
      })
      .catch(() => {
      })

    setIsBeingRedirected(false)
    setIsCancelBeingRedirected(false)
  }

  const continueButton = ( e, leagueId, roundId, selectedSlots ) => {
    e.preventDefault()
    setIsBeingRedirected(true)
  
    const reservedSlots = JSON.stringify(selectedSlots)
    const url = `/leagues/${leagueId}/widgets/players_choose_tee_times/reserve_selected_slots`
    callAPI(url, 'POST', {slots_to_reserve: reservedSlots, round_id: roundId})
      .then(() => {
        dispatch(toggleRefreshStatusBar(false))
        const ottMobileHeader = window.parent.document.querySelector('.ott-mobile-header')
        const ottMobileFooter = window.parent.document.querySelector('.ott-mobile-footer')

        if (ottMobileHeader) {
          window.parent.document.querySelector('.ott-mobile-header').remove()
        }

        if (ottMobileFooter) {
          window.parent.document.querySelector('.ott-mobile-footer').remove()
        }

        if (isPaymentsScenario) {
          if (!currentUser) {
            if (minMembersReg > 0) {
              window.top.location.href = `/websites/${websiteId}/login?round_id=${roundId}&booked_slots=${reservedSlots}&league_id=${leagueId}&payments_scenario=true&widgets_layout=true`
            } else if (minMembersReg < 0) {
              window.top.location.href = `/register?league_id=${leagueId}&booked_slots=${reservedSlots}&slots=${reservedSlots}`
            } else {
              window.location.href = `/leagues/${leagueId}/widgets/registrationv2/new_registration?show_powerd_by=false&booked_slots=${reservedSlots}`
            }
          } else if (currentUser) {
            window.location.href = `/leagues/${leagueId}/widgets/registrationv2/new_registration?show_powerd_by=false&booked_slots=${reservedSlots}`
          }
        } else if (userInMasterRoster) {
                    window.location.href = `/register?league_id=${leagueId}&autoregister=true&slots=${reservedSlots}&round_id=${roundId}&iframe=true`
        } else if (!currentUser && minMembersReg !== 0 ) {
          window.top.location.href = `/websites/${websiteId}/login?round_id=${roundId}&booked_slots=${reservedSlots}&league_id=${leagueId}&widgets_layout=true`
        } else {
          if (activeScreen === OttScreen.ADD_ADDITIONAL_PLAYERS) {
            window.location.href = `/leagues/${leagueId}/widgets/open_registration/edit?show_powerd_by=false&booked_slots=${reservedSlots}&round_id=${roundId}&iframe=true`
          } else {
            window.location.href = `/leagues/${leagueId}/widgets/open_registration/new?show_powerd_by=false&booked_slots=${reservedSlots}&round_id=${roundId}&iframe=true`
          }
        }
      })
      .catch((error) => {
        setIsBeingRedirected(true)
        openErrorPopup()
        if (error && error[0]) {
          createErrorPopupMessage(error[0])
          unselectSlotInCancelledFoursomes(error[1])
        } else {
          createErrorPopupMessage(window.I18n.t('players_choose_tee_times.ott_2_0.error.general'))
        }
      })
  }

  const unselectSlotInCancelledFoursomes = (foursomeIds) => {
    foursomeIds.forEach((foursomeId) => {
      dispatch(unselectALL(foursomeId))
    })
  }

  const saveButton = ( e, leagueId, roundId, selectedSlots, data ) => {
    e.preventDefault()
    setIsBeingRedirected(true)
  
    const reservedSlots = JSON.stringify(selectedSlots)
    const url = `/leagues/${leagueId}/widgets/players_choose_tee_times/reserve_selected_slots`
    callAPI(url, 'POST', {slots_to_reserve: reservedSlots, round_id: roundId, current_member_id: currentMemberId})
      .then(() => {
        movePlayerOnTeeSheetRequest( leagueId, roundId, data)
      })
      .catch((error) => {
        setIsBeingRedirected(true)
        openErrorPopup()
        if (error && error[0]) {
          createErrorPopupMessage(error[0])
        } else {
          createErrorPopupMessage(window.I18n.t('players_choose_tee_times.ott_2_0.error.general'))
        }
      })
  }

  const movePlayerOnTeeSheetRequest = async (leagueId, roundId, data) => {


    const url = `/leagues/${leagueId}/widgets/players_choose_tee_times/move_player_on_tee_sheet`
    callAPI(url, 'POST', { league_id: leagueId, round_id: roundId, from_slots: JSON.stringify(getPlayerIdsByOwnedMember(data)), to_slots: JSON.stringify(filterDataByOwnedMember(data))})
      .then(() => {
        reinitialiseState()
      })

  }

  const bookSlotsButton = async ( e, leagueId, roundId, selectedSlots, data, registeredMembers ) => {
    e.preventDefault()
    setIsBeingRedirected(true)
    const bookedSlots = JSON.stringify(selectedSlots)

    const reservedSlots = JSON.stringify(selectedSlots)
    const url = `/leagues/${leagueId}/widgets/players_choose_tee_times/reserve_selected_slots`
    callAPI(url, 'POST', {slots_to_reserve: reservedSlots, round_id: roundId})
      .then(async () => {
        await bookSlotsRequest( leagueId, roundId, registeredMembers, bookedSlots)
      })
      .catch((error) => {
        openErrorPopup()
        if (error && error[0]) {
          createErrorPopupMessage(error[0])
        } else {
          createErrorPopupMessage(window.I18n.t('players_choose_tee_times.ott_2_0.error.general'))
        }
      })
      .finally(() => {
        reinitialiseState()
      })
  }

  const bookSlotsRequest = async (leagueId, roundId, registeredMembers, bookedSlots) => {

    const url = `/leagues/${leagueId}/widgets/players_choose_tee_times/add_players_on_tee_sheet_ott2`
    const fromSlots = registeredMembers
    const toSlots = bookedSlots

    callAPI(url, 'POST', {
      from_slots: JSON.stringify(fromSlots),
      to_slots: JSON.stringify(toSlots),
      round_id: roundId,
      current_member_id: currentMemberId,
      league_id: leagueId,
    })
      .then(() => {
        reinitialiseState()
      })
      .catch(() => {
        openErrorPopup()
        setErrorPopupMessage(window.I18n.t('players_choose_tee_times.ott_2_0.error.unexpected'))
      })
  }

  const cancelButton = (e) => {
    e.preventDefault()

    setIsCancelBeingRedirected(true)
    reinitialiseState()

  }

  const confirmRemoveButton = async ( e, leagueId, roundId, data ) => {
    e.preventDefault()
  
    await fetch(`/leagues/${leagueId}/widgets/players_choose_tee_times/remove_players_from_tee_sheet`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        league_id: leagueId,
        round_id: roundId,
        user_id: userId,
        players_ids: getCheckedIdsByOwnedMember(data),
      }),
    })

    closePopup(e)
    reinitialiseState()
  }

  // View Helpers
  const isContinueButtonDisabled = (() => {
    if (isBeingRedirected || isLoggedInWithGgid ) {
      return true
    }
    if (activeScreen === OttScreen.CREATE_BOOKING) {
      return !isMinSlotsReached || !slotsArray.includes(selectedSlots.length)
    }
    if (activeScreen === OttScreen.ADD_ADDITIONAL_PLAYERS) {
      return !atLeastOneSlotSelected
    }
    return false
  })()

  const continueButtonContent = (() => {
    if (isBeingRedirected) {
      return window.I18n.t('players_choose_tee_times.ott_2_0.buttons.reserve_slots_redirected')
    } else {
      if (isContinueButtonDisabled) {
        return (<><img className="ott-info-icon" src={infoIcon}/> &nbsp;&nbsp; {window.I18n.t('players_choose_tee_times.ott_2_0.buttons.continue')}</>)
      } else {
        return (<>{window.I18n.t('players_choose_tee_times.ott_2_0.buttons.continue')}&nbsp;&nbsp;<img src={arrowIcon}/></>)
      }
    }
  })()

  const isSaveButtonDisabled = (() => {
    if (isBeingRedirected || isLoggedInWithGgid ) {
      return true
    }

    return !anySlotsChecked
  })()

  const saveButtonContent = (() => { 
    if (isBeingRedirected) {
      return window.I18n.t('players_choose_tee_times.ott_2_0.buttons.save_redirected')
    } else {
      return window.I18n.t('players_choose_tee_times.ott_2_0.buttons.save')
    }
  })()

  const bookSlotsButoonContent = (() => {
    if (isBeingRedirected) {
      return window.I18n.t('players_choose_tee_times.ott_2_0.buttons.book_slots_redirected')
    } else {
      return window.I18n.t('players_choose_tee_times.ott_2_0.buttons.book_slots')
    }
  })()

  const cancelButtonContent = (() => {
    if (isCancelRedirected) {
      return window.I18n.t('players_choose_tee_times.ott_2_0.buttons.cancel_redirected')
    } else {
      return window.I18n.t('players_choose_tee_times.ott_2_0.buttons.cancel')
    }
  })()

  const paymentInfoContent = (() => {
    return paymentsData
      .filter(row => Array.isArray(row.slots_array) && row.slots_array.includes(selectedSlots.length))
      .slice(3)
      .map(row => {
        const amount = row.per_individual ? (row.amount * selectedSlots.length).toFixed(2) : row.amount
        const perIndividualText = row.per_individual ? `• ${currency}${(row.amount * selectedSlots.length).toFixed(2)} / individual` : 'Entire Registration'

        return `${currency} ${amount} ${row.name} ${perIndividualText}`
      })
      .join('\n')
  })()

  useEffect(() => {
    const footerWrapper = footerWrapperRef.current

    const updatePadding = () => {
      if (footerWrapper.children.length > 0) {
        footerWrapper.classList.add('ott-footer-padding')
      } else {
        footerWrapper.classList.remove('ott-footer-padding')
      }
    }

    updatePadding()

    const observer = new MutationObserver(updatePadding)
    observer.observe(footerWrapper, { childList: true })

    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect()
    }
  }, [])

  // Render
  return (
    <footer>

        {
          isPaymentsScenario && activeScreen != OttScreen.CHANGE_TEE_TIME && activeScreen != OttScreen.REMOVE_PLAYERS && activeScreen != OttScreen.ADD_ADDITIONAL_PLAYERS &&

          <div className={'whole-container'}>
          <div key={'mykey2'} className={'totaler-box-style-mobile'}>
            {paymentsData.filter(row => Array.isArray(row.slots_array) && row.slots_array.includes(selectedSlots.length)).slice(0,3).map((row, index) => (
                <>
                  <div key={'mykey3'} className={'payment-container'}>
                    <div className={'value-payment'}> {currency} {row.per_individual ? (row.amount * selectedSlots.length).toFixed(2) : row.amount } </div>
                    <div className={'text-totaler-payment'}> {row.name}</div>
                    <div className={'text-totaler-payment'}> {row.per_individual ? '• ' + currency + row.amount + window.I18n.t('players_choose_tee_times.ott_2_0.payment_plans.individual') : window.I18n.t('players_choose_tee_times.ott_2_0.payment_plans.entire_registration')  }  </div>
                  </div>
                  {index < (paymentsData.filter(row => Array.isArray(row.slots_array) && row.slots_array.includes(selectedSlots.length)).length - 1) && <div className="vertical-line-mobile"></div>}
                </>
              ),
            )}
          </div>
            { paymentsData.filter(row => Array.isArray(row.slots_array) && row.slots_array.includes(selectedSlots.length)).length > 3 &&
              <GlgTooltip tooltip={paymentInfoContent} placement="top">
                <div className={'more-text'}> {paymentsData.filter(row => Array.isArray(row.slots_array) && row.slots_array.includes(selectedSlots.length)).length - 3} More </div>
              </GlgTooltip> }
          </div>

        }

      <div className={'ott-footer-style'} ref={footerWrapperRef} >

        {!([ OttScreen.EDIT_BOOKING, OttScreen.ADMIN_OR_MANAGER_SCREEN, OttScreen.SIGNUP_ER_MEMBER ].includes(activeScreen)) &&
          <button className={'cancel-buttons-style'} onClick={cancelButton}> {cancelButtonContent} </button>
        }

        {[ OttScreen.CREATE_BOOKING, OttScreen.ADD_ADDITIONAL_PLAYERS ].includes(activeScreen)
          ?
          (bookBeforeRegister === false ?

            (selectedSlots.length === registeredMembers.length && !isLoggedInWithGgid && currentUser
              ? 
              <button key="BookSlotsButton" onClick={ (e) => bookSlotsButton(e, leagueId, roundId, selectedSlots, data, registeredMembers)} className={'right-button-style enabled-bg-button-style'}>
                {bookSlotsButoonContent}
              </button> 
              :
                <GlgTooltip tooltip={isLoggedInWithGgid ? window.I18n.t('players_choose_tee_times.ott_2_0.tooltips.restricted_action') : currentUser ? window.I18n.t('players_choose_tee_times.ott_2_0.tooltips.reach_required_slots') : window.I18n.t('players_choose_tee_times.ott_2_0.tooltips.log_in_first')} placement="top">
                  <button key="BookSlotsButton" className={'right-button-style disabled-bg-button-style'}>
                    {bookSlotsButoonContent}
                  </button>
                </GlgTooltip>
            )
            :
          <div className={'note-container'}>
            {( isMobile === false && isBeingRedirected === false && isContinueButtonDisabled === true ?
            <GlgTooltip tooltip=
               {window.I18n.t('players_choose_tee_times.ott_2_0.tooltips.reach_required_slots',
                 {slotsNumber: `${minSlots} ${minSlots > 1 ?
                     window.I18n.t('players_choose_tee_times.ott_2_0.tooltips.slots')
                     : window.I18n.t('players_choose_tee_times.ott_2_0.tooltips.slot')}` })}
               placement="top">
              <button
                key="continueBttn"
                disabled={isContinueButtonDisabled}
                onClick={ (e) => continueButton(e, leagueId, roundId, selectedSlots)}
                className={isContinueButtonDisabled ? 'disabled-bg-button-style' : 'enabled-bg-button-style'}
              >
                {continueButtonContent}
              </button>
            </GlgTooltip>
            :

            <button
              key="continueBttn"
              disabled={isContinueButtonDisabled}
              onClick={ (e) => continueButton(e, leagueId, roundId, selectedSlots)}
              className={isContinueButtonDisabled ? 'disabled-bg-button-style' : 'enabled-bg-button-style'}
            >
              {continueButtonContent}
            </button>

            )}

            </div>)
          :
          activeScreen === OttScreen.REMOVE_PLAYERS 
            ?
              <button 
                key="ConfirmBttn" 
                onClick={ (e) => openPopup(e)}
                className={ `${checkedSlots.length === 0 ? 'disabled-bg-button-style' : 'red-button-style'}`}
                disabled={checkedSlots.length === 0}
              >
                Confirm&nbsp;&nbsp;
              </button>
            : (!([ OttScreen.EDIT_BOOKING, OttScreen.ADMIN_OR_MANAGER_SCREEN, OttScreen.SIGNUP_ER_MEMBER ].includes(activeScreen))) &&
              <button 
                onClick={ (e) => saveButton(e, leagueId, roundId, selectedSlots, data)} 
                className={`right-button-style ${isSaveButtonDisabled ? 'disabled-bg-button-style' : 'enabled-bg-button-style'}`}
                disabled={isSaveButtonDisabled}
              >
                {saveButtonContent}
              </button>
        }

        {isPopupOpen && (
          <div className={'popup-container-style'}>
            <div className={'popup-style'}>
              <div className={'ott-title-footer-style'}>Are you sure you want to remove {getCheckedNamesByOwnedMember(data, checkedSlots)} from your booking?</div>
              <div className={'button-container-style'}>
                <button className={'back-button-style'} onClick={ (e) => closePopup(e)}>
                  {window.I18n.t('players_choose_tee_times.ott_2_0.buttons.close')}
                </button>
                <button className={'confirm-button-style'} onClick={ (e) => confirmRemoveButton(e, leagueId, roundId, data, checkedSlots)}>
                  { window.I18n.t('players_choose_tee_times.ott_2_0.buttons.remove') }
                </button>
              </div>
            </div>
          </div>
        )}

        
        { isErrorPopupOpen && <OttErrorPopup message={errorPopupMessage} handleClose={closeErrorPopup} /> }

      </div>

      { isMobile && isOverlayVisible && displayMobileScroller && (
        <div className="scrolling-overlay">
          <div>
            <div className="triangle-bottom"></div>
          </div>
          <div className="scroll-text">SCROLL</div>
        </div> )}

      {isMobile && <OttRefreshStatusBar/>}
    </footer>
  )
}

export default OttFooter

