import {ActionTypes} from '../actions/setup_index'

const {
  EMPTY_TEAMSET_SETUP,
  ADD_NEW_TEAM,
  DELETE_TEAM,
  CHANGE_TEAM_NAME,
  CHANGE_TEAM_CAPTAIN,
  REORDER_TEAMS,
  CHANGE_ROUNDS_COUNT,
  SAVING_DRAFT,
  ERROR_SAVING_DRAFT,
} = ActionTypes

const initialState = {
  saveInProgress: false,
}
const misc = (state = initialState, action) => {
  switch (action.type) {
    case EMPTY_TEAMSET_SETUP: {
      const teams = Array.from({length: 5}, (_, i) => ({id: `t${i}`, name: '', position: i + 1}))
      return {
        ...state,
        teams: teams,
      }
    }
    case ADD_NEW_TEAM:
      return {
        ...state,
        teams: [ ...state.teams, {id: `t${Date.now()}`, name: '', position: state.teams.length + 1} ],
      }
    case DELETE_TEAM: {
      const idx = parseInt(action.data)
      const teams = [ ...state.teams.slice(0, idx), ...state.teams.slice(idx + 1) ].map((t, i) => {
        return {...t, position: i + 1}
      })
      const teamCaptains = state.teamCaptains
      delete teamCaptains[state.teams[idx].id]

      return {
        ...state,
        teams: teams,
        teamCaptains: teamCaptains,
      }
    }
    case CHANGE_TEAM_NAME: {
      const idx = parseInt(action.data.idx)
      return {
        ...state,
        teams: state.teams.map((team, i) => {
          if (i === idx) {
            return {
              ...team,
              name: action.data.newName,
            }
          }
          return team
        }),
      }
    }
    case CHANGE_TEAM_CAPTAIN:
      return {
        ...state,
        teams: state.teams.map(team => {
          if (team.id === action.data.teamId) {
            const captain = state.golfers.find(g => g.id === action.data.value)
            return {
              ...team,
              captain: {
                id: captain?.id,
                name: captain?.name,
              },
            }
          }
          return team
        }),
        teamCaptains: {
          ...state.teamCaptains,
          [action.data.teamId]: action.data.value,
        },
      }
    case CHANGE_ROUNDS_COUNT: {
      const teamsCount = state.teams.filter(t => t.name.trim().length > 0).length
      const captainsCount = Object.values(state.teamCaptains).filter(c => c).length
      return {
        ...state,
        roundsCount: teamsCount === 0 ? 0 : Math.ceil((state.golfers.length - captainsCount) / teamsCount),
      }
    }
    case REORDER_TEAMS:
      return {
        ...state,
        teams: action.data,
      }
    case SAVING_DRAFT:
      return {
        ...state,
        saveInProgress: true,
      }
    case ERROR_SAVING_DRAFT:
      return {
        ...state,
        saveInProgress: false,
      }
    default:
      return state
  }
}

export default misc
