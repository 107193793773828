import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'
import moment from 'moment'
import OttScreen from '../ott_screen'

const OttTitle = () => {
  useEffect(() => {
    window.glg.new_design_shared.computeAndApplyBorder()
  }, [])

  const roundFullName = useSelector(state => `${state.roundName}`)
  const leagueName = useSelector(state => `${state.leagueName}`)
  const roundDate = useSelector(state => state.roundPlayAt)
  const activeScreen = useSelector(state => state.activeScreen)
  const formattedDate = moment(roundDate).format('ddd, DD  MMM')

  return (
    <div>
      <div className='page_title'>
        <div className='text'>
          {leagueName.length > 35 && activeScreen === OttScreen.CREATE_BOOKING ? leagueName.slice(0, 30) + '...' : leagueName} - {roundFullName} ({formattedDate})
        </div>
      </div>
    </div>
  )
}

OttTitle .propTypes = {
  titleData: PropTypes.any,
}

export default OttTitle

