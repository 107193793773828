import { ActionTypes } from '../actions'

const {} = ActionTypes

const initialState = {
  customer_id: 0,
  category_lists: [],
  report_center_lists: [],
  league_id: '',
  round_id: '',
  enable_discover: false,
  is_team_captain: false,
  is_tm_lite: false,
  canUseLegacyScorecards: false,
}

const scorecards_center = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default scorecards_center