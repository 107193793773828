import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'react-bootstrap'
import styled from 'styled-components'
import Loader from 'react-loader-spinner'

const StyledModal = styled(Modal)`
  .modal-body {
    height: 320px;
  }
  
  .preview-container {
    margin: auto;
    margin-top: 40px;
    display: block;
    width: 15em; height: 10em;
    text-align: center;
    white-space: nowrap;
  }
  .preview-container img {
    display: block;
    width: auto; height: auto;
    max-width: 100%; max-height: 100%;
    vertical-align: middle;
  }
  .preview-container:after {
    display: block;
    height: 100%; width: 0;
    vertical-align: middle;
    content: "";
  }

`

export const AddHeaderLogoPopup = (props) => {
  const [ imagePreview, setImagePreview ] = useState({ image: '' })
  const [ disableImageSave, sedDisableImageSave ] = useState(true)

  const handleImageUpload = (e) => {
    const file = e.target.files[0]
    setImagePreview({image: URL.createObjectURL(file), imageFile: file})

    let message = ''
    if (file.size > 6000000) { // bytes
      message = 'File exceeds maximum size.'
    }
    
    if ([ 'image/jpeg', 'image/png', 'image/gif', 'image/bmp' ].indexOf(file.type) < 0) {
      message += ' File format should be jpg, jpeg, gif, png, bmp.'
    }

    if (message !== '') {
      $('.error-message').removeClass('hidden')
      $('.error-message').html(message)
      sedDisableImageSave(true)

    } else {
      $('.error-message').addClass('hidden').disabled = false
      sedDisableImageSave(false)
    }
  }

  const handleModalClose = () => {
    props.setModalOpen(false)
    setImagePreview({ image: '' })
  }

  const saveImage = () => {
    props.setImages([ ...props.images, imagePreview ])
    handleModalClose()
  }

  return (
    <StyledModal show={props.modalOpen} onHide={() => handleModalClose()}>
      <Modal.Header closeButton>
      {window.I18n.t('views.golfhub.add_header_logo_photo')}
      </Modal.Header>
      <Modal.Body>
        <input className='add-margin-bottom-10' type='file' accept='image/jpeg, image/png, image/gif, image/bmp' onChange={(e) => handleImageUpload(e)}/>
        <div className='help-message add-margin-bottom-10'>
          <div>{window.I18n.t('views.golfhub.accepted_photo_formats')}</div>
        </div>
        <div className='error-message btsp_alert_notices hidden add-margin-bottom-10 add-padding-all-5'></div>
        <div className='preview-container'>
          <img src={imagePreview.image} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => handleModalClose()}>Close</Button>
        <Button className='add-margin-left-10 button btsp_orange_button' disabled={!imagePreview.image || disableImageSave} onClick={() => saveImage()} >Save</Button>
      </Modal.Footer>
    </StyledModal>
  )
}

export const HeaderLogoItem = ({headerLogo, setDeleted, setModalOpen, showLoading, themeColor}) => {
  return (
    <Fragment>
      {headerLogo.id === '' && (
        <div className='add-margin-left-15 btsp_bullet_button pointer' onClick={() => setModalOpen(true)}>
          <div className='icon'><i className='gga-circle-plus'></i></div>
          <div className='btsp_align-middle'>{window.I18n.t('views.golfhub.upload_logo')}</div>
        </div>
      )}
      {headerLogo.id !== '' && (
        <div className='header-logo-item'>
          <div className='container header-logo-container'>
            <img src={headerLogo.image || headerLogo.photos[0].header_url} className='header-logo-image' style={{backgroundColor: themeColor}} />
          </div>
          {showLoading === true && (
            <span className="span pull-right glg-loading-spinner header-logo-loading">
              <Loader type="Oval" color={ '#0088CE' } height={ 20 } width={ 30 }></Loader>
            </span>
          )}
          <i className='fa fa-trash fa-lg add-margin-left-5' onClick={setDeleted}></i>
        </div>
      )}
    </Fragment>
  )
}

AddHeaderLogoPopup.propTypes = {
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
  images: PropTypes.array,
  setImages: PropTypes.func,
}

HeaderLogoItem.propTypes = {
  headerLogo: PropTypes.object,
  setModalOpen: PropTypes.func,
  setDeleted: PropTypes.func,
  showLoading: PropTypes.bool,
  themeColor: PropTypes.string,
}

export default HeaderLogoItem
