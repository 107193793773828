import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { interpretPlaceholders, computeHeight } from '../../helpers'

class TextComponent extends Component {
  constructor(props) {
    super(props)

    this.hexToRgb = this.hexToRgb.bind(this)
    this.updateScrollRate = this.updateScrollRate.bind(this)
  }

  componentDidMount() {
    const { slide, setPlaceholderValues } = this.props
    
    if (slide.placeholderValues && setPlaceholderValues) {
      setPlaceholderValues(slide.placeholderValues)
    }
    this.updateScrollRate()
  }

  componentWillMount() {
    clearInterval(this.scrollingInterval)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.slide.id !== this.props.slide.id || prevProps.blueBox !== this.props.blueBox) {
      this.updateScrollRate()
    }
  }

  updateScrollRate() {
    const { slide, blueBox, moveToNextSlide } = this.props

    clearInterval(this.scrollingInterval)
    this.scrollingInterval = setInterval(() => {
      if (blueBox.isScrolling) {
        clearInterval(this.scrollingInterval)
        moveToNextSlide()
      }
    }, slide.scrollRate * 1000 / blueBox.scrollRate)
  }

  hexToRgb(hex) {
    const bigint = parseInt(hex, 16)
    const r = (bigint >> 16) & 255
    const g = (bigint >> 8) & 255
    const b = bigint & 255

    return [ r, g, b ].join()
  }

  render() {
    const { slide, tvShowSettings, blueBox, advancedSlideHeaderHeight } = this.props

    let divStyle = {
      height: `calc(100vh - ${ computeHeight(tvShowSettings, blueBox, advancedSlideHeaderHeight) }px)`,
      borderRadius: '5px',
    }
    if (slide.bgColor && slide.bgOpacity) {
      divStyle = {
        ...divStyle,
        backgroundColor: `rgba(${this.hexToRgb(slide.bgColor.replace('#', ''))}, ${ slide.bgOpacity / 100 })`,
      }
    }

    return <div style={ divStyle }><span dangerouslySetInnerHTML={{__html: interpretPlaceholders(slide.text, slide) }}></span></div>
  }
}

TextComponent.propTypes = {
  isFireTv: PropTypes.bool,
  slide: PropTypes.object.isRequired,
  blueBox: PropTypes.object,
  moveToNextSlide: PropTypes.func.isRequired,
  setPlaceholderValues: PropTypes.func,
  placeholderValues: PropTypes.object,
  hasUSGATheme: PropTypes.bool,
  tvShowSettings: PropTypes.object,
  advancedSlideHeaderHeight: PropTypes.number,
}

TextComponent.defaultProps = {
  isFireTv: false,
  hasUSGATheme: false,
}

export default TextComponent
