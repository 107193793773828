import { connect } from 'react-redux'

import ScorecardsCenter from '../scorecards_center'

import { showPopup, onClose, doNotShow} from '../actions'

const mapStateToProps = (state) => {
  return {
    customer_id: state.scorecards_center.customer_id,
    category_lists: state.scorecards_center.category_lists,
    report_center_lists: state.scorecards_center.report_center_lists,
    league_id: state.scorecards_center.league_id,
    round_id: state.scorecards_center.round_id,
    enable_discover: state.scorecards_center.enable_discover,
    is_team_captain: state.scorecards_center.is_team_captain,
    is_tm_lite: state.scorecards_center.is_tm_lite,
    canUseLegacyScorecards: state.scorecards_center.canUseLegacyScorecards,
    do_not_show: state.popup.do_not_show,
    show_phase_out_popup: state.popup.show_phase_out_popup,
    open_modal: state.popup.open_modal,
    url: state.popup.url,
  }
}

const mapDispatchToProps = (dispatch) => ({
  showPopup: (data) => dispatch(showPopup(data)),
  onClose: () => dispatch(onClose()),
  doNotShow: (checked) => dispatch(doNotShow(checked)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScorecardsCenter)
