import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Modal, Button } from 'react-bootstrap'
import { GlgSelect } from '../../../shared/components'

export const HubFiltersPopup = (props) => {

  const [ firstOptionSelected, setFirstOptionSelected ] = useState(false)
  const [ firstValueSelected, setFirstValueSelected ] = useState(0)
  const [ secondSelectedOption, setSecondSelectedOption ] = useState('')
  const [ existingData, setExistingData ] = useState(false)

  if (!existingData) {
    const newExcludedOptions = []
    const existingFilters = props.data.role_setup_filters
    if (existingFilters !== null){
      for (let i = 0; i < existingFilters.length; ++i){
        newExcludedOptions.push({category: existingFilters[i].category, option: existingFilters[i].option})
      }
    }
    setExistingData(true)
    props.setExcludedOptions(newExcludedOptions)
  }

  let options = [
    { id: 1, name: 'Event Type' },
    { id: 2, name: 'Gender' },
    { id: 3, name: 'Age' },
  ]

  let secondaryOptions = [
    { id: 1, values: [ { id: 1, name: 'Event' }, { id: 2, name: 'League' }, {id: 3, name: 'Clinic' } ] },
    { id: 2, values: [ { id: 1, name: 'Male' }, { id: 2, name: 'Female' } ] },
    { id: 3, values: [ { id: 1, name: 'Senior' }, { id: 2, name: 'Adult' }, {id: 3, name: 'Junior' } ] },
  ]

  const isTGACustomer = props.formData.encrypted_id === 'd5ef9bcb-f5bf-4c9e-b750-c3f0dffc8a7d' ||
                        props.formData.encrypted_id === 'e0efddd8-b6c7-41e4-bbff-bbfadd9f753e' ||
                        props.formData.encrypted_id === 'c4f5b566-57c6-4aec-915d-767d9141a797' ||
                        (props.formData.encrypted_id === '4a4e39b1-fca5-4caa-9dcf-98d068b2c55b' && !props.isProd)

  if (props.availableSeasons?.length > 0 && (props.formData.aggregate_from === 'none' || isTGACustomer)) {
    options = [ ...options, { id: 4, name: 'Season' } ]
    secondaryOptions = [ ...secondaryOptions, { id: 4, values: props?.availableSeasons } ]
  }

  const handleModalClose = () => {
    setFirstOptionSelected(false)
    props.setFilterModalOpen(false)
  }

  const onFirstOptionSelected = optionSelected => {
    setFirstValueSelected(optionSelected)
    setFirstOptionSelected(true)
    setSecondSelectedOption('')
  }

  const onSecondOptionSelected = optionSelected => {
    setSecondSelectedOption(optionSelected)
  }

  const excludeOption = (category, option) => {
    const newExcludedOptions = [ ...props.excludedOptions ]
    newExcludedOptions.push( {category: category, option: option} )
    props.setExcludedOptions(newExcludedOptions)
  }

  const categoryList = (options, ) => {
    const list = []
    for (let i = 0; i < options.length; ++i) {
      if (isCategoryAvailable(options[i])) {
        list.push(({label: options[i].name, value: options[i].id}))
      }
    }
    if (list.length === 0){
      props.setNoOptions(true)
    }
    return list
  }

  const isCategoryAvailable = (option) => {
    let count = 0
    const categoryName = options[option.id - 1].name
    for (let i = 0; i < props.excludedOptions.length; ++i) {
      if (props.excludedOptions[i].category === categoryName) {
        count++
      }
    }
    if (count !== secondaryOptions[option.id - 1].values.length) {
      return true
    }
    return false
  }

  const optionsList = (options) => {
    const list = []
    for (let i = 0; i < options.length; ++i) {
      if (isOptionAvailable(options[i])) {
        list.push(({label: options[i].name, value: options[i].name}))
      }
    }
    return list
  }

  const isOptionAvailable = (option) => {
    for (let i = 0; i < props.excludedOptions.length; ++i) {
      if (props.excludedOptions[i].option === option.name){
        return false
      }
    }
    return true
  }

  const onSave = () => {
    const category = options[firstValueSelected - 1].name
    const option = secondSelectedOption ? secondSelectedOption : optionsList(secondaryOptions[firstValueSelected - 1].values)[0].label

    const isFilterAdded = props.filtersSelected?.some(filter => filter.option === option && filter._destroy === undefined)
    if (isFilterAdded){
      handleModalClose()
      return
    }

    excludeOption(category, option)
    const destroyedFilter = props.filtersSelected?.find(filter => filter.option === option)
    const newOptionSelected = { category: category, option: option }
    if (destroyedFilter !== undefined && destroyedFilter !== null) {
      const newFilters = [ ...props.filtersSelected ]
      newFilters.map(filter => {
        if (filter.option === option){
          filter._destroy = undefined
        }
      })

      props.setData({ ...props.data, role_setup_filters: newFilters })
    } else {
      const newFilters = [ ...props.filtersSelected, newOptionSelected ]

      props.setData({ ...props.data, role_setup_filters: newFilters })
    }

    handleModalClose()
  }

  return (
    <Modal className='modal-golfhub' show={props.filterModalOpen} onHide={() => handleModalClose()}>
      <Modal.Header className='modal-header-golfhub'>
        <Modal.Title>
          { window.I18n.t('bootstrap_nav.apps.golf_hub.add_filters') }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-body-golfhub'>
        <div className='content'>
          <div className='selection-content'>
            <p className='inner-paragraph-content-golfhub'>{ window.I18n.t('bootstrap_nav.apps.golf_hub.category') }</p>
            <GlgSelect
              name={'selected_category_name'}
              items={ categoryList(options) }
              noPreFill={ true }
              placeholder='Select a Category'
              width='165px'
              onChange={optionSelected => onFirstOptionSelected(optionSelected)}
            />
            {
              firstOptionSelected &&
                <>
                  <p className='inner-paragraph-content-golfhub'>{ window.I18n.t('bootstrap_nav.apps.golf_hub.option') }</p>
                  <GlgSelect
                    name={ 'selected_category_option' }
                    items={ optionsList(secondaryOptions[firstValueSelected - 1].values) }
                    noPreFill={ true }
                    value={secondSelectedOption ? secondSelectedOption : optionsList(secondaryOptions[firstValueSelected - 1].values)[0].label}
                    width='160px'
                    onChange={ optionSelected => onSecondOptionSelected(optionSelected) }
                  />
                </>
            }
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='footer modal-footer-golfhub'>
        <Button onClick={() => handleModalClose()} className = 'cancel-button'>Cancel</Button>
        <Button onClick={() => onSave()} disabled={!firstOptionSelected} className='button btsp_orange_button save-button'>Add</Button>
      </Modal.Footer>
    </Modal>
  )
}

HubFiltersPopup.propTypes = {
  filterModalOpen: PropTypes.bool,
  setFilterModalOpen: PropTypes.func,
  filtersSelected: PropTypes.array,
  data: PropTypes.object,
  setData: PropTypes.func,
  excludedOptions: PropTypes.array,
  setExcludedOptions: PropTypes.func,
  noOptions: PropTypes.bool, 
  setNoOptions: PropTypes.func,
  availableSeasons: PropTypes.array,
  formData: PropTypes.object,
  isProd: PropTypes.bool,
}
