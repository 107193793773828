import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { connect } from 'react-redux'
import JrLeagueDashboard from '../components/jrleague_dashboard'
import JrLeagueDashboardScramble from '../components/jrleague_dashboard_scramble'
import { fetchData } from '../actions/index'
import Loader from 'react-loader-spinner'

import PropTypes from 'prop-types'

const JrDashboardMainContainer = ({ rounds, refreshUrl, round }) => {
  const [ selectedRound, setSelectedRound ] = useState(round || rounds[0][0])
  const dispatch = useDispatch()

  const { matchesResults, dashboardType, parArray, loading } = useSelector(
    (state) => state.misc
  )

  useEffect(() => {
    dispatch(fetchData(selectedRound, refreshUrl, true))
  }, [ selectedRound, refreshUrl, dispatch ])

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(fetchData(selectedRound, refreshUrl, false))
    }, 30000)

    return () => clearInterval(interval)
  }, [ selectedRound, refreshUrl, dispatch ])

  const handleRoundClick = (id) => {
    setSelectedRound(id)
  }

  return (
    <div>
      <div className="jrleague-selector">
        <div className="round-selector">
          {rounds.map(([ id, name ]) => (
            <div
              key={id}
              className={`round-option ${selectedRound === id ? 'selected' : ''}`}
              onClick={() => handleRoundClick(id)}
            >
              {name}
            </div>
          ))}
        </div>
      </div>

      {loading ? (
        <div className="spinner-container">
          <Loader
            type="Oval"
            color="#00BFFF"
            height={100}
            width={100}
            visible={true}
          />
        </div>
      ) : (
        <div>
          {dashboardType === 'flags' && matchesResults ? (
            <JrLeagueDashboard key={selectedRound} matchesResults={matchesResults} parArray={parArray} />
          ) : dashboardType === 'scramble' && matchesResults ? (
            <JrLeagueDashboardScramble key={selectedRound} matchesResults={matchesResults} parArray={parArray} />
          ) : (
            <div>Loading dashboard...</div>
          )}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    rounds: state.misc.rounds,
    refreshUrl: state.misc.refreshUrl,
    round: state.misc.round,
  }
}

JrDashboardMainContainer.propTypes = {
  rounds: PropTypes.array,
  refreshUrl: PropTypes.string,
  round: PropTypes.string,
}

export default connect(mapStateToProps)(JrDashboardMainContainer)
