import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import { GlgCheckbox } from 'SharedComponents'
import ReactDOMServer from 'react-dom/server'

const PhaseOutPopup = ({show, onOk, onClose, doNotShow}) => {
  return (
    <Modal className="phase_out_popup" show={show} popup="true" onHide={ onClose }>
      <Modal.Header closeButton={ true }>
        <Modal.Title className="text-center">
          { !window.I18n ? '' : window.I18n.t('scorecards_center.title_warning') }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          { !window.I18n ? '' : window.I18n.t('scorecards_center.phase_out_warning') }
          <br></br>
          <br></br>
          <span dangerouslySetInnerHTML={{
            __html: window.I18n.t(
              'scorecards_center.learn_more',
              { link: ReactDOMServer.renderToString(<a href={'https://docs.golfgenius.com/article/show/11477-printing-scorecards?adminView=true'} className='blue' target='_blank'>{window.I18n.t('scorecards_center.here')}</a>) }),
          }} />
        </div>
      </Modal.Body>
      <Modal.Footer className="popup_footer">
        <div className='do_not_show'>
          <GlgCheckbox
            name='do_not_show'
            inline={true}
            label={ !window.I18n ? '' : window.I18n.t('scorecards_center.do_not_show')}
            checked={false}
            onChange={(checked) => doNotShow(checked)}
          />
        </div>
        <Button type="button" className="btn button btsp_orange_button ok_button"
                onClick={onOk}>
          {window.I18n.t('buttons.ok')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

PhaseOutPopup.propTypes = {
  show: PropTypes.bool,
  onOk: PropTypes.func,
  onClose: PropTypes.func,
  doNotShow: PropTypes.func,
}

export default PhaseOutPopup
