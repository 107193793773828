import React from 'react'
import PropTypes from 'prop-types'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import _ from 'lodash'
import {GlgTooltipIcon} from '../../../../shared/components'

const DraftOrderSection = ({
  changeSection,
  teams,
  reorderTeams,
  order,
  changeOrder,
  roundsCount,
  roundsOrder,
  changeRoundOrder,
}) => {

  const handleOnDragEnd = (result) => {
    const {source, destination} = result
    if (!destination) {
      return
    }

    let _teams = [ ...teams ]
    const _team = _teams.splice(source.index, 1)[0]
    _teams.splice(destination.index, 0, _team)
    _teams = _teams.map((t, i) => {
      return {...t, position: i + 1}
    })

    reorderTeams(_teams)
  }

  return (
    <div className="section" id="draft-order-section">
      <hr/>
      <div className="section_header" onClick={(e) => changeSection(e)}>
        <div className="row no-margin-right">
          <div className="col-xs-11 col-sm-7 col-lg-6 title">
            <div className="label label-primary">4.</div>
            <span className="header_text">{window.I18n.t('draft.components.draft_order_component.title')}</span>
          </div>
          <div className="hidden-xs col-sm-4 col-lg-5"/>
          <div className="col-xs-1 caret-container add-padding-top-15">
            <i className="gga-carret-down"/>
            <i className="gga-carret-up"/>
          </div>
        </div>
      </div>
      <hr/>

      <div className="section_content btsp_square_solid hidden">
        {roundsCount === 0 && <div className="row">
          <strong>{window.I18n.t('draft.components.draft_order_component.note')}</strong>
          {window.I18n.t('draft.components.draft_order_component.add_teams_first')}
        </div>}
        {roundsCount > 0 &&
          <>
            <div className="row">
              <label className="draft_label btsp_uppercase_text">
                {window.I18n.t('draft.components.draft_order_component.first_round_order')}
              </label>
            </div>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="teams">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps} className="row drag_drop_area">
                    {teams.filter(t => t.name.trim().length > 0).map((team, tIdx) =>
                      <Draggable key={tIdx} draggableId={team.id} index={tIdx}>
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}
                               className="col-xs-12 col-sm-12 col-md-12 col-lg-12 team_comp">
                            <div className="col-xs-10 col-sm-8 col-md-6 col-lg-4 no_padding team-container">
                              <i className="fa fa-bars icon"></i>
                              <div className="form-control team-info">
                                <div className="team-name">{team.name}</div>
                                {team.captain?.id && <div className="captain">Captain: {team.captain.name}</div>}
                              </div>
                            </div>
                            <div className="col-xs-10 col-sm-8 col-md-6 col-lg-4"/>
                          </div>)}
                      </Draggable>)}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </>
        }

        {roundsCount > 1 &&
          <>
            <div className="row">
              <label className="draft_label btsp_uppercase_text add-margin-top-30">
                {window.I18n.t('draft.components.draft_order_component.remaining_rounds_draft_order')}
              </label>
            </div>

            <div className="row">
              <label key="recurring" className="col-xs-12 col-md-3 icheck-label no-padding-left">
                <div className={`iradio_square-orange icheck-item ${order === 'recurring' ? 'checked' : ''}`}>
                  <input className="i-checks icheck-input" type="radio" value="recurring"
                         onChange={e => changeOrder(e.target.value)} checked={order === 'recurring'}/>
                </div>
                <span>{window.I18n.t('draft.components.draft_order_component.recurring')}</span>
                <GlgTooltipIcon tooltip={window.I18n.t('draft.components.draft_order_component.recurring_tooltip')} placement="bottom" iconClass="add-margin-left-5 add-top-3"/>
              </label>

              <label key="snake" className="col-xs-12 col-md-3 icheck-label">
                <div className={`iradio_square-orange icheck-item ${order === 'snake' ? 'checked' : ''}`}>
                  <input className="i-checks icheck-input" type="radio" value="snake"
                         onChange={e => changeOrder(e.target.value)} checked={order === 'snake'}/>
                </div>
                <span>{window.I18n.t('draft.components.draft_order_component.snake')}</span>
                <GlgTooltipIcon tooltip={window.I18n.t('draft.components.draft_order_component.snake_tooltip')} placement="bottom" iconClass="add-margin-left-5 add-top-3"/>
              </label>

              <label key="combination" className="col-xs-12 col-md-3 icheck-label">
                <div className={`iradio_square-orange icheck-item ${order === 'combination' ? 'checked' : ''}`}>
                  <input className="i-checks icheck-input" type="radio" value="combination"
                         onChange={e => changeOrder(e.target.value)} checked={order === 'combination'}/>
                </div>
                <span>{window.I18n.t('draft.components.draft_order_component.combination')}</span>
                <GlgTooltipIcon tooltip={window.I18n.t('draft.components.draft_order_component.combination_tooltip')} placement="bottom" iconClass="add-margin-left-5 add-top-3"/>
              </label>
            </div>

            {order === 'combination' &&
              <div className="row">
                <div className="col-lg-8 col-md-10 col-sm-12 col-xs-12 no-padding-left">
                  <div className="table-responsive">
                    <table className="table combination_table">
                      <thead>
                      <tr className="header">
                        <th className="round">{window.I18n.t('draft.components.draft_order_component.round')}</th>
                        <th>
                          {window.I18n.t('draft.components.draft_order_component.first_round_order')}
                          <GlgTooltipIcon tooltip={window.I18n.t('draft.components.draft_order_component.first_round_order_tooltip')} placement="bottom" iconClass="add-margin-left-5 add-top-3"/>
                        </th>
                        <th>
                          {window.I18n.t('draft.components.draft_order_component.first_round_reversed_order')}
                          <GlgTooltipIcon tooltip={window.I18n.t('draft.components.draft_order_component.first_round_reversed_order_tooltip')} placement="bottom" iconClass="add-margin-left-5 add-top-3"/>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        _.times(roundsCount - 1, (i) =>
                          <tr key={i} className={`aggregate-row ${i & 1 ? 'odd' : 'even'}`}>
                            <td className="round">{window.I18n.t('draft.components.draft_order_component.round_index', {index: i + 2})}</td>
                            <td className="recurring">
                              <label className="icheck-label">
                                <div
                                  className={`iradio_square-orange icheck-item ${roundsOrder[i] === 'recurring' ? 'checked' : ''}`}>
                                  <input className="i-checks icheck-input" type="radio" value="recurring"
                                         onChange={e => changeRoundOrder(i, e.target.value)}
                                         checked={roundsOrder[i] === 'recurring'}/>
                                </div>
                              </label>
                            </td>
                            <td className="snake">
                              <label className="icheck-label">
                                <div
                                  className={`iradio_square-orange icheck-item ${roundsOrder[i] === 'snake' ? 'checked' : ''}`}>
                                  <input className="i-checks icheck-input" type="radio" value="snake"
                                         onChange={e => changeRoundOrder(i, e.target.value)}
                                         checked={roundsOrder[i] === 'snake'}/>
                                </div>
                              </label>
                            </td>
                          </tr>,
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>}
          </>}
      </div>
    </div>
  )
}

DraftOrderSection.propTypes = {
  changeSection: PropTypes.func,
  teams: PropTypes.array,
  reorderTeams: PropTypes.func,
  order: PropTypes.string,
  changeOrder: PropTypes.func,
  roundsCount: PropTypes.number,
  roundsOrder: PropTypes.any,
  changeRoundOrder: PropTypes.func,
}

export default DraftOrderSection
