import React from 'react'
import PropTypes from 'prop-types'
import rootReducer from './reducers'
import configureStore from 'Shared/configure_store'
import { GlobalProvider } from 'Shared/global_context'

import JrDashboardMainContainer from './components/jrleague_dashboard_main'

const JrLeagueDashboardRoot = ( {matchesResults, rounds, refreshUrl, parArray, round}) => {
  const state = {
    misc: {
      matchesResults,
      rounds,
      refreshUrl,
      parArray,
      round,
    },
  }
  const store = configureStore(rootReducer, state)

  return (
    <GlobalProvider store = { store }>
        <JrDashboardMainContainer />
    </GlobalProvider>
  )
}

JrLeagueDashboardRoot.propTypes = {
  matchesResults: PropTypes.array,
  rounds: PropTypes.array,
  refreshUrl: PropTypes.string,
  parArray: PropTypes.array,
  round: PropTypes.string,
}

export default JrLeagueDashboardRoot
