import {connect} from 'react-redux'
import NowPickingArea from '../../components/manager_side/picking_area/now_picking_area'
import {autoPick, draftGolfer, removeFromNowPicking} from '../../actions/manager_side_index'

const mapStateToProps = (state) => ({
  nowPicking: state.draftManagerSide.nowPicking,
  pickTime: state.draftManagerSide.pickTime,
  draftsIndexUrl: state.draftManagerSide.draftsIndexUrl,
  saveInProgress: state.draftManagerSide.saveInProgress,
  picks: state.draftManagerSide.picks,
})

const mapDispatchToProps = (dispatch) => ({
  draftGolfer: () => dispatch(draftGolfer()),
  removeFromNowPicking: () => dispatch(removeFromNowPicking()),
  autoPick: () => dispatch(autoPick()),
})

export default connect(mapStateToProps, mapDispatchToProps)(NowPickingArea)
