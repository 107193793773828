import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const teeData = ({tees, isMultiRound, hasFourballTournament, toggleThead}) => {
  const shouldShowMRTColumns = !hasFourballTournament && isMultiRound
  return (
    <Fragment>
      <tr className='header' onClick={toggleThead} style={{ cursor: 'pointer' }}>
        <th className='with_border first'>{window.I18n.t('shot_by_shot.tee_data.group_info')}</th>
        <th className='with_border second'>{window.I18n.t('shot_by_shot.tee_data.hole')}</th>
        { shouldShowMRTColumns && <th className='with_border'>{window.I18n.t('shot_by_shot.tee_data.prior')}</th> }
        { [ ...Array(9) ].map( ( _, i) => <th key={'hole-' + i} className='with_border'>{i + 1}</th>) }
        <th className='with_border'>{window.I18n.t('shot_by_shot.tee_data.out')}</th>
        { [ ...Array(9) ].map( ( _, i) => <th key={'hole-' + i} className='with_border'>{i + 10}</th>) }
        <th className='with_border'>{window.I18n.t('shot_by_shot.tee_data.in')}</th>
        <th className='with_border'>{window.I18n.t('shot_by_shot.tee_data.score')}</th>
        { shouldShowMRTColumns && <th className='with_border'>{window.I18n.t('shot_by_shot.tee_data.today')}</th> }
        { shouldShowMRTColumns && <th className='with_border'>{window.I18n.t('shot_by_shot.tee_data.total')}</th> }
      </tr>
      {
        Object.keys(tees).map( par => {
          return tees[par].map( (tee, index) =>  
            <Fragment key={tee.id}>
              <tr className='yardage_row'>
                <td className='first'>{tee.name}</td>
                <td className='second'>Yardage</td>
                { shouldShowMRTColumns && <td></td> }
                { [ ...Array(9) ].map( ( _, i) => <td key={'hole-' + i}>{tee.yardage[i]}</td>) }
                <td>{tee.yardage_sum_front}</td>
                { [ ...Array(9) ].map( ( _, i) => <td key={'hole-' + i}>{tee.yardage[i + 9]}</td>) }
                <td>{tee.yardage_sum_back}</td>
                <td>{tee.yardage_sum}</td>
                { shouldShowMRTColumns && <td></td> }
                { shouldShowMRTColumns && <td></td> }
              </tr>
              { index === tees[par].length - 1 && 
                <tr className='par_row'>
                  <td className='first'></td>
                  <td className='second'>Par</td>
                  { shouldShowMRTColumns && <td></td> }
                  { [ ...Array(9) ].map( ( _, i) => <td key={'hole-' + i}>{tee.par[i]}</td>) }
                  <td>{tee.par_sum_front}</td>
                  { [ ...Array(9) ].map( ( _, i) => <td key={'hole-' + i}>{tee.par[i + 9]}</td>) }
                  <td>{tee.par_sum_back}</td>
                  <td>{tee.par_sum}</td>
                  { shouldShowMRTColumns && <td></td> }
                  { shouldShowMRTColumns && <td></td> }
                </tr> 
              }
            </Fragment>
          )
        })
      }
    </Fragment>
  )
}

teeData.propTypes = {
  tees: PropTypes.object,
  isMultiRound: PropTypes.bool,
  hasFourballTournament: PropTypes.bool,
  toggleThead: PropTypes.func,
  hasFourballTournament: PropTypes.bool,
}

export default teeData
