import React from 'react'
import PropTypes from 'prop-types'
import {Button, Modal} from 'react-bootstrap'

const AutoPickPopup = ({open, onButtonClicked, saveInProgress}) => {
  return (
    <Modal className="draft_popup" show={open} popup="true">
      <Modal.Header>
        <Modal.Title className="text-center">
          {window.I18n.t('draft.components.manager_side.auto_pick_popup.auto_pick_confirm_text')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer className="popup_buttons">
        <Button type="button" className="btn button btsp_gray_button" disabled={saveInProgress}
                onClick={() => onButtonClicked(false)}>
          {window.I18n.t('buttons.cancel')}
        </Button>
        <Button type="button" className="btn button btsp_orange_button" disabled={saveInProgress}
                onClick={() => onButtonClicked(true)}>
          {window.I18n.t(`buttons.${saveInProgress ? 'saving' : 'continue'}`)}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

AutoPickPopup.propTypes = {
  open: PropTypes.bool,
  onButtonClicked: PropTypes.func,
  saveInProgress: PropTypes.bool,
}

export default AutoPickPopup
