import { connect } from 'react-redux'
import EventSetupRounds from '../../components/content/event_setup_rounds.jsx'
import { NotificationManager } from 'react-notifications'
import {
  setGenericData,
  updateRounds,
  editRound,
  toggleRoundSignup,
  scheduleSignupsOpenClose,
  eventSetupWidgetDoJob,
  clearWidgetContent,
} from '../../actions/index'
import moment from 'moment'

// const mapStateToProps = (state) => {}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { links, rounds, updatingData, saveInProgress, scheduleSignups, enableOTT, multipleUpdateActions } = ownProps.content
  const internationalize = (key, data = null) => !window.I18n ? '' : window.I18n.t(key, data)

  return {
    setData: (keys, value) => dispatch(setGenericData(ownProps.widgetId, keys, value)),
    addRound: (date) => {
      NotificationManager.info(internationalize('dashboard_plus.widgets.adding_round', { date }))
      dispatch(setGenericData(ownProps.widgetId, [ 'updatingData', 'adding_round' ], true))
      dispatch(updateRounds(ownProps.widgetId, links.add_round.path, { 'date': date }, links.get_rounds.path))
        .finally(() => {
          dispatch(setGenericData(ownProps.widgetId, [ 'updatingData', 'adding_round' ], false))
        })
      dispatch(clearWidgetContent('event_setup_tournaments', 'event_setup_tee_sheet'))
    },
    removeRound: (roundId) => {
      dispatch(setGenericData(ownProps.widgetId, [ 'updatingData', roundId ], 'removeRound'))
      dispatch(updateRounds(ownProps.widgetId, links.remove_round.path, { 'round_id': roundId }, links.get_rounds.path))
      dispatch(clearWidgetContent('event_setup_tournaments', 'event_setup_tee_sheet'))
    },
    updateRoundDate: (roundId, newDate) => dispatch(editRound(
      ownProps.widgetId,
      decodeURI(links.update_play_at.path).replace(/{round_id}/g, roundId),
      {
        init_play_at: moment.unix(rounds.find(r => r.id === roundId).play_at).format('D MMMM Y'),
        play_at: moment.unix(newDate).format('D MMMM Y'),
      },
      [ rounds.findIndex(r => r.id === roundId), 'play_at' ],
      newDate)),
    updateRoundName: (roundId, name) => dispatch(editRound(
      ownProps.widgetId,
      decodeURI(links.update_round.path).replace(/{round_id}/g, roundId),
      {
        _method: 'put',
        round: {
          name: name,
        },
      },
      [ rounds.findIndex(r => r.id === roundId), 'name' ],
      name,
    )),
    toggleSignups: (roundId) => {
      if (updatingData[roundId]) { return }

      dispatch(setGenericData(ownProps.widgetId, [ 'updatingData', roundId ], 'toggleSignups'))
      dispatch(toggleRoundSignup(
        ownProps.widgetId,
        decodeURI(links.toggle_signups.path).replace(/{round_id}/g, roundId),
        roundId,
        [ rounds.findIndex(r => r.id === roundId), 'signups_open', 'value' ]))
    },
    doAction: () => {
      if (saveInProgress) { return }

      const selectedRounds = rounds.filter(r => r.selected).map(r => r.id)

      switch (multipleUpdateActions.value) {
        case 'schedule_registration':
          return dispatch(scheduleSignupsOpenClose(ownProps.widgetId, links.multiple_update.path, {
            selected_rounds: selectedRounds,
            attribute: multipleUpdateActions.value,
            open_days: scheduleSignups.open.days_before,
            open_hours: scheduleSignups.open.hour,
            open_minutes: scheduleSignups.open.min,
            open_am_pm: scheduleSignups.open.am_pm,
            close_days: scheduleSignups.close.days_before,
            close_hours: scheduleSignups.close.hour,
            close_minutes: scheduleSignups.close.min,
            close_am_pm: scheduleSignups.close.am_pm,
          }))

        case 'enable_tee':
          return dispatch(eventSetupWidgetDoJob(ownProps.widgetId, links.rounds_enable_tee.path, {
            selected_rounds: selectedRounds,
            reserved_foursome_number: enableOTT.reserved_tee_times,
            hide_players_on_tee_times: enableOTT.hide_players_on_tee_times,
            ott_ggid_read_only: enableOTT.ott_ggid_read_only,
          }))

        case 'disable_tee':
          return dispatch(eventSetupWidgetDoJob(ownProps.widgetId, links.rounds_disable_tee.path, {
            selected_rounds: selectedRounds,
          }))

        default:
          break
      }
    },
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(EventSetupRounds)
