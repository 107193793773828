import React, { Component } from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'

import { onConfirm } from 'Shared/helpers'

import ReportCenterItemInline from './report_center_item_inline'
import ReportCenterItemBlock from './report_center_item_block'
import ReportCenterItemPublished from './report_center_item_published'
import ReportCenterItemBlockPublished from './report_center_item_block_published'
import ReportCenterItemInlineArchived from './report_center_item_inline_archived'
import ReportCenterItemBlockArchived from './report_center_item_block_archived'

export default class ReportCenterItemBase extends Component {
  constructor(props) {
    super(props)
    const dm = this.props.report_center_item.denied_message
    this.state = {
      edit_name: false,
      job: { id: null, error: false, completed_links: null, show_spiner: false, status_updates: []},
      show_preview: false,
      originalContextLink: (dm && new RegExp('^http').test(dm)) ? dm : null,
    }

    this.toggleItemName = this.toggleItemName.bind(this)
    this.saveItemName = this.saveItemName.bind(this)
    this.printReport = this.printReport.bind(this)
    this.pollJob = this.pollJob.bind(this)
    this.updateRciThumbnailUrl = this.updateRciThumbnailUrl.bind(this)
    this.downloadFileFromUrl = this.downloadFileFromUrl.bind(this)
    this.setJobId = this.setJobId.bind(this)
    this.generateDocx = this.generateDocx.bind(this)
    this.generateTxt = this.generateTxt.bind(this)
    this.redirectUnlessJobsRunning = this.redirectUnlessJobsRunning.bind(this)
    this.deleteItem = this.deleteItem.bind(this)
    this.togglePreview = this.togglePreview.bind(this)
    this.importEvent = this.importEvent.bind(this)
    this.unarchiveItem = this.unarchiveItem.bind(this)
  }

  importEvent(rci, rcl, defaultAction, actionType) {
    const importUrl = rci.needs_import
    if (importUrl) {
      fetch(importUrl, { method: 'PATCH' })
        .then(response => response.json())
        .then(data => {
          this.props.importItem(data['rci'], data['rcl_id'])

          let newAction
          if (data['rci']['edit_path'].flat().length === 2) {
            newAction = data['rci']['edit_path']
          } else {
            newAction = data['rci']['edit_path'].filter((action) => { return action[0] === actionType })[0]
          }

          if (newAction !== undefined && newAction[0] !== 'Copy' && newAction[0] === actionType) {
            this.redirectUnlessJobsRunning(newAction[1])
          }
        })

    } else {
      if (actionType === 'Copy') {
        this.props.cloneItem(this.props.report_center_item, this.props.current_report_center_list.id)
      } else {
        this.redirectUnlessJobsRunning(defaultAction)
      }
    }
  }

  toggleItemName() {
    this.setState(update(this.state, {
      edit_name: { $set: !this.state.edit_name },
    }))
  }

  togglePreview() {
    this.setState(update(this.state, {
      show_preview: { $set: !this.state.show_preview },
    }))
  }

  deleteItem() {
    if (this.props.current_report_center_list.archive_list) { // the report is already placed in Report Archive Category
      onConfirm(!window.I18n ? '' : window.I18n.t('download_center.delete_question'), () => {
        $.ajax('/api/report_center/report_center_items/' + this.props.report_center_item.id, {
          method: 'DELETE',
          data: { league_id: this.props.league_id },
          success: function () {
            this.props.removeAllItemInstances(this.props.report_center_item.id)
          }.bind(this),
        })
      })
    } else {
      this.props.toggleArchiveItem(this.props.report_center_item, 'archive')
    }
  }

  saveItemName(value) {
    $.ajax('/api/report_center/report_center_items/' + this.props.report_center_item.id, {
      method: 'PATCH',
      data: { report_center_item: { name: value }, league_id: this.props.league_id },
      success: function () {
        this.props.updateItemStateAttribute(this.props.report_center_item.id, 'name', value)
        this.toggleItemName()
      }.bind(this),
    })
  }

  //print report by calling rci's url or throught the provided parameters
  printReport(_url = null, _type = 'GET', _data = {}, _callback = () => {}) {
    let url = _url || this.props.report_center_item.action[1]

    if (this.props.report_center_item.report_type === 'TeamPlayMatch') {
      _type = 'POST'
    }

    url = url.indexOf('?') < 0 ? url + '?report_center_item_id=' + String(this.props.report_center_item.id) : url + '&report_center_item_id=' + String(this.props.report_center_item.id)
    
    if (_data.spec) {
      url = url + '&spec_id=' + _data.spec
    }

    $.ajax(url, {
      method: _type,
      dataType: 'json',
      data: _data,
      error: function() {
        this.setJobId({id: null, error: true})
      }.bind(this),
      success: function (data) {
        _callback()
        if (data.job_id){ //no conflicts
          this.setJobId({ id: data.job_id, error: false, show_spiner: true })
        } else if (data.edit_conflicts_path) { //conflicts or another form rul response
          const is_legacy = this.props.report_center_item.report_type === 'ScorecardSetting'
          //tell modal to load form from edit_conflicts_path and submit throught printReport or cancel throught setJobId of this very rci
          this.props.openPrintModal({ edit_conflicts_path: data.edit_conflicts_path, rci_base_printReport: this.printReport, rci_base_setJobId: this.setJobId, is_legacy: is_legacy})
        } else if (data.window_redirect){
          //when printing a spreadsheet report a redirect to the edit report might be received as a response
          window.location.href = data.window_redirect
        } else if (data.window_redirect_new_tab){
          window.open(data.window_redirect_new_tab)
        }
      }.bind(this),
    })
  }

  generateDocx() {
    this.generateFile('/docx/convert')
  }

  generateTxt() {
    this.generateFile('/txt/convert')
  }

  generateFile(requestPath) {
    this.setJobId({ show_spiner: true })
    $.ajax(requestPath, {
      method: 'POST',
      dataType: 'json',
      data: {
        url: this.state.job.completed_links.pdf_path,
        name: this.props.report_center_item.name,
        primary_docx_service: this.state.job.completed_links.primary_docx_service,
        docx_path: this.state.job.completed_links.docx_path,
        generate_txt: this.state.job.completed_links.generate_txt,
        txt_path: this.state.job.completed_links.txt_path,
        league_id: this.props.league_id,
      },
      error: () => {
        this.setJobId({id: null, error: true})
      },
      success: (data) => {
        if (data.job_id){ //no conflicts
          this.setJobId({ id: data.job_id, error: false })
        } else if (data.edit_conflicts_path) { //conflicts
          this.props.openPrintModal({ edit_conflicts_path: data.edit_conflicts_path, rci_base_setJobId: this.setJobId})
        }
      },
    })
  }

  pollJob (jobId) {
    //regular timed requests
    setTimeout(() => {
      $.ajax('/jobs/' + jobId + '/poll', {
        method: 'GET',
        dataType: 'json',
        success: (messages) => {
          const lastMessage = messages[messages.length - 1]
          //update status_updates tooltip
          if (lastMessage && lastMessage['type'] === 'message') {
            if (this.state.job.status_updates !== lastMessage['value']) {
              this.setJobIdNoPolling({
                status_updates: this.state.job.status_updates.concat(lastMessage['value']),
              }, () => {})

              setTimeout(() => {
                window.redo_qtip()
              }, 250)
            }
          }

          //done
          if (lastMessage && lastMessage['type'] === 'job-update' && lastMessage['value']['status'] === 'completed') {
            if (lastMessage['value']['pdf_path'] || lastMessage['value']['docx_path'] || lastMessage['value']['txt_path']){
              const completedLinks = {
                pdf_path: lastMessage['value']['pdf_path'] || (this.state.job.completed_links ? this.state.job.completed_links.pdf_path : null),
                button_label: lastMessage['value']['button_label'] || (this.state.job.completed_links ? this.state.job.completed_links.button_label : 'Download'),
                docx_path: lastMessage['value']['docx_path'],
                primary_docx_service: lastMessage['value']['primary_docx_service'],
                txt_path: lastMessage['value']['txt_path'],
                generate_txt: lastMessage['value']['generate_txt'],
              }
              this.setJobId({
                id: null,
                error: false,
                completed_links: completedLinks,
                status_updates: [],
              })
              if (lastMessage['value']['print_count']) {
                this.props.updateItemStateAttribute(this.props.report_center_item.id, 'print_count', lastMessage['value']['print_count'])
              }
              //this.downloadFileFromUrl(lastMessage["value"]["pdf_path"] || lastMessage["value"]["docx_path"]);
            } else {
              this.setJobId({id: null, error: true, completed_links: null})
            }
          } else if (lastMessage && lastMessage['value']['status'] === 'errors') {
            //errors
            this.setJobId({id: null, error: true, completed_links: null })
            return
          } else {
            //in progress - keep polling
            //TO DO: handle status updates
            this.pollJob(jobId)
          }
        },
      })
    }, 2000) //polling interval 3s
  }

  updateRciThumbnailUrl(thumbnailUrl) {
    this.setState(update(this.state, { report_center_item: { image_url: { $set: thumbnailUrl }}}))
  }

  //TO DO create a single dummy anchor for all download calls
  downloadFileFromUrl(url, fileName = '') {
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = url
    a.download = fileName
    a.click()
    window.URL.revokeObjectURL(url)
  }

  setJobIdNoPolling(jobHash, _callback) {
    jobHash['show_spiner'] = jobHash.show_spiner || false
    jobHash = $.extend(this.state.job, jobHash) //just update jobHash keys
    this.setState({ job: jobHash }, () => {}, _callback)
  }

  //TO DO: make it clear this will also start the polling
  setJobId(jobHash, _callback) {
    jobHash['show_spiner'] = jobHash.show_spiner || false
    jobHash = $.extend(this.state.job, jobHash) //just update jobHash keys
    this.setState({ job: jobHash }, () => {
      if (jobHash.id !== null) {
        this.pollJob(jobHash.id)
      }
    }, _callback)
  }

  //show confirm window if jobs running
  //hack - getting app state from native dom
  redirectUnlessJobsRunning(url) {
    const jobsRunning = $('tr.in_progress, div.in_progress').length > 0
    
    const is_legacy = this.props.report_center_item.report_type === 'ScorecardSetting'

    const handleRedirect = () => {
      is_legacy ? this.props.showPhaseOutPopup(url) : (window.location.href = url);
    }

    if (!jobsRunning) {
      handleRedirect()
    } else {
      onConfirm(
        window.I18n?.t('scorecards_center.report_center_item_base.leave_page') || '', () => {
        handleRedirect()
      });
    }
  }
  
  unarchiveItem() {
    this.props.toggleArchiveItem(this.props.report_center_item, 'unarchive')
  }

  render() {
    const _functions = {
      toggleItemName: this.toggleItemName,
      updateItemName: this.updateItemName,
      saveItemName: this.saveItemName,
      printReport: this.printReport,
      pollJob: this.pollJob,
      updateRciThumbnailUrl: this.updateRciThumbnailUrl,
      downloadFileFromUrl: this.downloadFileFromUrl,
      setJobId: this.setJobId,
      updateListCount: this.updateListCount,
      generateDocx: this.generateDocx,
      generateTxt: this.generateTxt,
      togglePrintOptionsModal: this.togglePrintOptionsModal,
      redirectUnlessJobsRunning: this.redirectUnlessJobsRunning,
      deleteItem: this.deleteItem,
      togglePreview: this.togglePreview,
      importEvent: this.importEvent,
      unarchiveItem: this.unarchiveItem,
    }

    return this.props.mode === 'download_center' ?
      this.props.layout === 'inline' ?
        this.props.current_report_center_list.archive_list ? 
          <ReportCenterItemInlineArchived {...{ ...this.props, ...this.state, ..._functions }} />
          :
          <ReportCenterItemInline {...{ ...this.props, ...this.state, ..._functions }} />
        :
        this.props.current_report_center_list.archive_list ? 
          <ReportCenterItemBlockArchived {...{ ...this.props, ...this.state, ..._functions }} />
          :
          <ReportCenterItemBlock {...{ ...this.props, ...this.state, ..._functions }} />
      :
      this.props.layout === 'inline' ?
        <ReportCenterItemPublished {...{ ...this.props, ...this.state, ..._functions}} />
        :
        <ReportCenterItemBlockPublished {...{ ...this.props, ...this.state, ..._functions}} />
  }
}

ReportCenterItemBase.propTypes = {
  report_center_item: PropTypes.object,
  league_id: PropTypes.string,
  layout: PropTypes.string,
  mode: PropTypes.string,
  openPrintModal: PropTypes.func,
  updateItemStateAttribute: PropTypes.func,
  removeAllItemInstances: PropTypes.func,
  isTeamCaptain: PropTypes.bool.isRequired,
  cloneItem: PropTypes.func,
  importItem: PropTypes.func,
  current_report_center_list: PropTypes.object,
  redirectUnlessJobsRunning: PropTypes.func,
  toggleArchiveItem: PropTypes.func.isRequired,
  showPhaseOutPopup: PropTypes.func,
}
