import React, { Fragment, useEffect, useState } from 'react'
import { GlgRadio } from '../../../shared/components'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal, Button } from 'react-bootstrap'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Loader from 'react-loader-spinner'

const StyledModal = styled(Modal)`
  .modal-body {
    height: 320px;
  }
  
  .preview-container {
    margin: auto;
    margin-top: 40px;
    display: block;
    width: 15em; height: 10em;
    text-align: center;
    white-space: nowrap;
  }
  .preview-container img {
    display: block;
    width: auto; height: auto;
    max-width: 100%; max-height: 100%;
    vertical-align: middle;
  }
  .preview-container:after {
    display: block;
    height: 100%; width: 0;
    vertical-align: middle;
    content: "";
  }

`

const DraggableItem = styled.div`
  
  margin-top: 15px;

  .container {
    display: inline-block;
    width: fit-content;
    height: fit-content;
    margin: 0px;
    padding: 5px;
    text-align: center;
    white-space: nowrap;
  }
  .container img {
    display: inline-block;
    width: 30px;
    height: 30px;
    vertical-align: middle;
  }
  .container:after {
    display: inline-block;
    height: 100%; width: 0;
    vertical-align: middle;
    content: "";
  }
  
  .form-control {
    width: auto;
    display: inline;
  }

  i {
    cursor: pointer;
    margin-left: -31px;
  }

  .sponsor-url {
    width: calc(100% - 31px);
    max-width: 150px;
  }
`

const GalleryPhotoDisplay = (props) => {
  const swapImages = (result) => {
    const tempArr = [ ...props.images ]
    const tempVar = tempArr[ result.source.index ]
    tempArr.splice(result.source.index, 1)
    tempArr.splice(result.destination.index, 0, tempVar)

    props.setFormData({...props.formData, custom_gallery: 'new'})
    props.setImages(tempArr)
  }

  const removeImage = (index) => {
    const updatedImages = props.images.filter((_, i) => i !== index)
    props.handleImages(updatedImages)
  }

  const beautyFileName = (fileName) => {
    if (fileName !== null) {
      const lastDotIndex = fileName.lastIndexOf('.')
      const extension = lastDotIndex !== -1 ? fileName.slice(lastDotIndex) : ''
      const name = lastDotIndex !== -1 ? fileName.slice(0, lastDotIndex) : fileName
  
      return `${name.slice(0, 20)}${name.length > 19 ? '...' : ''}${extension}`
    }
  
    return 'file'
  }

  return (
    <Fragment>
      {props.images.length > 0 && (
        <DragDropContext onDragEnd={swapImages}>
          <Droppable droppableId="droppable">
          {(provided) => (
            <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className='img-container'>
              
              {props.images.map((img, i) => (
                <Draggable key={'draggable-' + i} draggableId={'draggable-' + i} index={i} >
                  {(prov) => (
                    <DraggableItem
                      ref={prov.innerRef}
                      {...prov.draggableProps}
                      {...prov.dragHandleProps}>
                      <div className="custom-image-drg">
                        <i className='fa fa-bars fa-lg add-margin-right-10 draggable-icon-container'></i>
                        <div className="custom-image-container">
                          <div className="image-details">
                            <div className='container'><img src={img.image} key={i} /></div>
                            <div className="details">
                              <div className="image-name">{beautyFileName(img.imageFile.name)}</div>
                              <div className="image-dimension">{(img.imageFile.size / 1024 / 1024).toFixed(2)}MB</div>
                            </div>
                          </div>
                          {img.newImage && (
                            <span className="span pull-right glg-loading-spinner sponsor-loading">
                              <Loader type="Oval" color={ '#0088CE' } height={ 20 } width={ 30 }></Loader>
                            </span>
                          )}
                          <div className="remove-image" onClick={() => removeImage(i)}>
                            <i className="fa-trash custom-trash"></i>
                          </div>
                        </div>
                      </div>
                    </DraggableItem>
                  )}
                </Draggable>
              ))}
            </div>
          )}
          </Droppable>
        </DragDropContext>
      )}
    </Fragment>
  )
}

export const AddCustomGalleryModal = (props) => {
  const [ imagePreview, setImagePreview ] = useState({ image: '' })
  const [ disableImageSave, sedDisableImageSave ] = useState(true)

  const handleImageUpload = (e) => {
    const file = e.target.files[0]
    setImagePreview({image: URL.createObjectURL(file), imageFile: file, newImage: true, processing: false})

    let message = ''
    if (file.size > 6000000) { // bytes
      message = 'File exceeds maximum size.'
    }
    
    if ([ 'image/jpeg', 'image/png', 'image/gif', 'image/bmp' ].indexOf(file.type) < 0) {
      message += ' File format should be jpg, jpeg, gif, png, bmp.'
    }

    if (message !== '') {
      $('.error-message').removeClass('hidden')
      $('.error-message').html(message)
      sedDisableImageSave(true)

    } else {
      $('.error-message').addClass('hidden').disabled = false
      sedDisableImageSave(false)
    }
  }

  const handleModalClose = () => {
    setImagePreview({ image: '' })
    props.setModalOpen(false)
  }

  const saveImage = () => {
    props.setImages([ ...props.images, imagePreview ])
    setImagePreview({ image: '' })
    handleModalClose()
  }

  return (
    <StyledModal show={props.modalOpen} onHide={() => handleModalClose()}>
      <Modal.Header closeButton>
        {window.I18n.t('views.golfhub.add_custom_gallery_photo')}
      </Modal.Header>
      <Modal.Body>
        <input className='add-margin-bottom-10' type='file' accept='image/jpeg, image/png, image/gif, image/bmp' onChange={(e) => handleImageUpload(e)}/>
        <div className='help-message add-margin-bottom-10'>
          <div>{window.I18n.t('views.golfhub.accepted_photo_formats')}</div>
          <div>{window.I18n.t('views.golfhub.custom_gallery_acceptable_format')}</div>
        </div>
        <div className='error-message btsp_alert_notices hidden add-margin-bottom-10 add-padding-all-5'></div>
        <div className='preview-container'>
          <img src={imagePreview.image} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => handleModalClose()}>Close</Button>
        <Button className='add-margin-left-10 button btsp_orange_button' disabled={disableImageSave} onClick={() => saveImage()}>Save</Button>
      </Modal.Footer>
    </StyledModal>
  )
}

const serializeImages = (data) => {
  return data.photos.map(photo => ({
    id: photo.id,
    image: photo.logo_url,
    imageFile: {
      name: photo.name,
      size: photo.size,
      type: 'image/png',
    },
  }))
}

export const RotatingPhotoGallery = (props) => {
  const [ initialApplied, setInitialApplied ] = useState(false)
  const existingAlbum = props.initialImages?.photos?.length > 0

  useEffect(() => {
    if (!initialApplied && existingAlbum) {
      props.setFormData({ ...props.formData, custom_gallery: props.initialImages.id })
      props.setImages(serializeImages(props.initialImages))
      setInitialApplied(true)
    }
  }, [ props.initialImages ])

  return (
    <>
      <div className="rotating-photo-gallery">
        <div className={`default-gallery rotating-radio ${!props.use_custom_gallery ? 'mark-text' : ''}`}>
          <GlgRadio
            name="default_gallery"
            label={ window.I18n.t('views.golfhub.gallery.default_gallery') }
            checked={ !props.use_custom_gallery }
            onChange={() => props.setFormData({ ...props.formData, use_custom_gallery: !props.use_custom_gallery })}
          />
        </div>
        <div className={`custom-gallery rotating-radio ${props.use_custom_gallery ? 'mark-text' : ''}`}>
          <div className="custom-gallery-radio">
            <GlgRadio
              name="custom_gallery"
              label={ window.I18n.t('views.golfhub.gallery.custom_gallery') }
              checked={ props.use_custom_gallery }
              onChange={() => props.setFormData({ ...props.formData, use_custom_gallery: !props.use_custom_gallery })}
            />
          </div>
          {props.use_custom_gallery && props.images.length < 6 && (
            <div className='add-margin-left-15 btsp_bullet_button pointer' onClick={() => props.setModalOpen(true)}>
              <div className='icon'><i className='gga-circle-plus'></i></div>
              <div className='btsp_align-middle'>{window.I18n.t('views.golfhub.upload_photos')}</div>
            </div>
          )}
        </div>
      </div>
      {props.use_custom_gallery && (
        <>
          {props.images.length > 0 && (
            <GalleryPhotoDisplay 
              images={props.images}
              isLoading={props.isLoading}
              setImages={props.setImages}
              handleImages={props.handleImages}
              formData={props.formData}
              setFormData={props.setFormData}
              existingAlbum={existingAlbum}
            />
          )}
        </>
      )}
    </>
  )
}

RotatingPhotoGallery.propTypes = {
  use_custom_gallery: PropTypes.bool,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  setModalOpen: PropTypes.func,
  isLoading: PropTypes.bool,
  images: PropTypes.array,
  setImages: PropTypes.func,
  initialImages: PropTypes.object,
  handleImages: PropTypes.func,
}

AddCustomGalleryModal.propTypes = {
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
  images: PropTypes.array,
  setImages: PropTypes.func,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  existingAlbum: PropTypes.bool,
}

GalleryPhotoDisplay.propTypes = {
  images: PropTypes.array,
  isLoading: PropTypes.bool,
  setImages: PropTypes.func,
  handleImages: PropTypes.func,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
}
