import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import IframeResizer from 'iframe-resizer-react'

const StyledPage = styled.div`
  .name-input {
    width: 400px;
  }
`

const IframeContainerThumbnail = (props) => {
  const iframeRef = useRef(null)
  const [ iframeLoaded, setIframeLoaded ] = useState(false)

  useEffect(() => {
    const thumbnailPreview = props.thumbnail

    const hash = {
      thumbnail: thumbnailPreview,
      name: props.data.name,
      event_start_date: `${props.data?.event_start_date?.toString()}` || '',
      event_end_date: `${props.data?.event_end_date?.toString()}` || '',
      loading: props.data.loading,
    }

    if (iframeLoaded) {
      iframeRef.current.sendMessage(hash)
    } else {
      window.setTimeout(() => {
        iframeRef.current.sendMessage(hash)
      }, 1000)
    }
  }, [ props.thumbnail, props.data ])

  const sendInitialHash = () => {
    const thumbnailPreview = props.thumbnail

    const initializerHash = {
      thumbnail: thumbnailPreview,
      name: props.data.name,
      event_start_date: `${props.data.event_start_date.toString()}`,
      event_end_date: `${props.data.event_end_date.toString()}`,
    }

    window.setTimeout(() => {
      setIframeLoaded(true)
      iframeRef.current.sendMessage(initializerHash)
    }, 1000)
  }

  return (
    <StyledPage>
      <IframeResizer
        forwardRef={iframeRef}
        src={props.data.src}
        style={{ width: '265px', height: '260px', padding: 0, border: '1px solid #999' }}
        onInit={sendInitialHash}
        id={'directoryThumbnailPreview'}
      ></IframeResizer>
    </StyledPage>
  )
}

IframeContainerThumbnail.propTypes = {
  thumbnail: PropTypes.object,
  data: PropTypes.object,
}

export default IframeContainerThumbnail
