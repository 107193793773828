import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Checkbox from './checkbox'
import { GlgSelect } from 'SharedComponents'

const internationalize = (...args) => !window.I18n ? '' : window.I18n.t(...args)

const switchExpression = (lookupTable) => {
  const processedLookupTable = Object.keys(lookupTable).reduce((newTable, oldKey) => {
    oldKey.split('|').forEach(key => newTable[key] = lookupTable[oldKey])
    return newTable
  }, {})
  const search = (key) => processedLookupTable[key]

  return search
}

const parseIndex = (index) => {
  if (index[0] === '+' || index[0] === 'p' || index[0] === 'P') { return -1 * parseFloat(index.slice(1)) }
  return parseFloat(index)
}

const partitionByGroups = (total, groupCount) => {
  if (groupCount === 0) { return [] }

  const base = Array(groupCount).fill(Math.floor(total / groupCount))
  const excess = Array(total % groupCount).fill(1)

  return base.map((v, i) => v + (excess[i] || 0))
}

const partitionByMax = (total, max) => {
  return partitionByGroups(total, max === 0 ? 0 : Math.ceil(total / max))
}

export const categoryItemBuilder = (category) => (name, genderAnswers, numberOfGolfers, tee = null) => ({
  name: name,
  index_up_to: '',
  [`golfers_per_${category}`]: numberOfGolfers,
  genders: Object.fromEntries(genderAnswers.map(gender => [ gender, gender === genderAnswers[0] ])),
  age_range: {
    from: '',
    to: '',
  },
  ...(tee !== null ? {
    course_and_tee: {
      tee: tee.tee,
      hole: tee.hole,
      virtual_id: tee.virtual_id,
    },
  } : {}),
})

function DivisionsFlightsTable({
  category,
  withIndexUpTo,
  withNumberOfGolfers,
  withGenders,
  oneGender,
  withAgeRange,
  genderAnswers,
  tees,
  rowClass,
  setCount,
  items,
  fixedItems,
  setItemData,
  indexes,
  setIndex,
  golfersDivisionsFlights,
  setError,
  errorCheckingDependencies,
}) {
  const categoryLabel = internationalize(`dashboard_plus.divisions_flights.${category}`)

  const headers = [ {
    name: 'name',
    label: internationalize('dashboard_plus.divisions_flights.name', { category: categoryLabel }),
    enabled: true,
  }, {
    name: 'index_up_to',
    label: rowClass === 'combined_index_usga_index' ? internationalize('divisions.inprogress_division.index_up_to') : internationalize('divisions.inprogress_division.playing_handicap_up_to'),
    enabled: withIndexUpTo,
  }, {
    name: `golfers_per_${category}`,
    label: internationalize('dashboard_plus.divisions_flights.number_of_golfers', { category: categoryLabel }),
    enabled: withNumberOfGolfers,
  }, {
    name: 'genders',
    label: internationalize('divisions.index.age_gender.genders'),
    enabled: withGenders,
  }, {
    name: 'age_range',
    label: internationalize('divisions.index.age_gender.age_range'),
    enabled: withAgeRange,
  }, {
    name: 'course_and_tee',
    label: internationalize('dashboard_plus.event_setup_divisions.course_and_tee'),
    enabled: tees && Object.values(tees).length > 0,
  } ]
  const enabledHeaders = headers.filter(x => x.enabled)

  const buildItem = categoryItemBuilder(category)
  const newItem = (name, numberOfGolfers = 0) => {
    let teeWithHole = null
    if (tees && Object.values(tees).length > 0) {
      const hole = Object.values(tees)[0].holes['all']
      teeWithHole = {
        tee: Object.values(tees)[0].value,
        hole: hole?.value,
        virtual_id: hole?.virtual_id,
      }
    }
    return buildItem(name, genderAnswers, numberOfGolfers, teeWithHole)
  }

  const addItem = () => {
    if (categoryLabel === 'Division') {
      if (fixedItems.type !== 'disabled' ) {
        const selected = golfersDivisionsFlights.selected
        setCount(parseInt(golfersDivisionsFlights.choices[selected].value + 1))
      }
    } else {
      if (fixedItems.type !== 'disabled' ) {
        const selected = golfersDivisionsFlights.selected
        setCount(parseInt(golfersDivisionsFlights.choices[selected].value + 1))
      }
    }
    setItemData([ items.length ], newItem(`${categoryLabel} ${items.length + 1}`))
  }

  const resetItems = () => {
    if (fixedItems.type !== 'disabled' ) { 
      setCount(0)
      const localIndexes = JSON.parse(JSON.stringify(indexes))
      items.forEach((item) => {
        if (Array.isArray(item.no_golfers)) {
          localIndexes.push(...item.no_golfers)
        }
      })
      setIndex(localIndexes)
    }
    setItemData([], [])
  }

  useEffect(() => {
    if (fixedItems.type !== 'disabled' && Array.isArray(indexes) && category === 'division') { 
      const localIndexes = JSON.parse(JSON.stringify(indexes))
      items.forEach(item => {
        if (Array.isArray(item.no_golfers)) {
          localIndexes.push(...item.no_golfers)
        }
      })
      setIndex(localIndexes)
      setCount(0)
      setItemData([], [])
    }
  }, [ withIndexUpTo ])

  useEffect(() => {
    if (fixedItems.type === 'fixed') {
      while (items.length < fixedItems.value) {
        items.push(newItem(`${categoryLabel} ${items.length + 1}`))
      }
      items = items.slice(0, fixedItems.value)
      setItemData([], items)

    } else if (fixedItems.type === 'max_golfers') {
      const partitions = partitionByMax(fixedItems.total, fixedItems.value)
      const newItems = partitions.map((count, index) => newItem(`${categoryLabel} ${index + 1}`, count))
      if (items.length !== 0 || newItems.length !== 0) { setItemData([], newItems) }
    }
  }, (fixedItems.dependencies || [ fixedItems.value ]))

  useEffect(() => {
    const errorKey = `${DivisionsFlightsTable.name}_${category}`

    let error = false
    items.find(item => {
      enabledHeaders.find(header => {
        const element = item[header.name]
        error = (switchExpression({
          'name': () => element.trim().length > 0 ? false : internationalize('dashboard_plus.divisions_flights.error_messages.name_empty', { category: categoryLabel }),
          'index_up_to': () => {
            if (/[pP+-]?\d+-\d+/.test(element) || /\.{2,}/.test(element) || /[^pP+\d.]+/.test(element) || /\.$/.test(element)) {
              return internationalize('dashboard_plus.divisions_flights.error_messages.invalid_handicap_index')
            } else {
              return false
            }
          },
          [`golfers_per_${category}`]: () => parseInt(element) > 0 ? false : internationalize('dashboard_plus.divisions_flights.error_messages.positive_number', { element: header.label }),
          'age_range': () => {
            const from = parseInt(element.from)
            const to = parseInt(element.to)
            return isNaN(from) || isNaN(to) || from <= to ? false : internationalize('dashboard_plus.divisions_flights.error_messages.invalid_age_range')
          },
          'course_and_tee': () => element.tee !== '' && element.hole !== '' ? false : internationalize('dashboard_plus.divisions_flights.error_messages.select_course_tee'),
        })(header.name) || (() => false))()

        return error
      })
      return error
    })

    if (fixedItems.type === 'max_golfers') {
      const totalGolfers = items.map(item => parseInt(item[`golfers_per_${category}`]) || 0).reduce((a, b) => a + b, 0)
      if (totalGolfers > fixedItems.total) {
        error = internationalize('assets.application.flights.total_greater', { count: fixedItems.total })
      }
    }
    if (items.length === 0) {
      error = internationalize('dashboard_plus.divisions_flights.error_messages.empty_table', { category: categoryLabel })
    }
    setError(errorKey, error)

    return () => { setError(errorKey, undefined) }
  }, errorCheckingDependencies)

  return (
    <div>
      <table className={`${category}s_table table table-striped`}>
        <thead>
          <tr className='header'>
            {
              enabledHeaders.map((header, index) =>
                <th key={`${header.name}-${index}`}>{ header.label }</th>)
            }
          </tr>
        </thead>
        <tbody>
          {
            items.length === 0 ?
              <tr>
                <td className='center' colSpan={headers.length}>{ internationalize(`dashboard_plus.divisions_flights.no_${category}s`) }</td>
              </tr>
              :
              items.map((item, index) => {
                return (
                  <tr key={`${category}_${index}`}>
                    {
                      /* eslint-disable */
                      enabledHeaders.map(header =>
                        <td className={`${category}_${header.name} ${rowClass} ${tees ? '' : 'no-tees'}`} key={`${category}_${index}_${header.name}`}>
                          {
                            switchExpression({
                              name: () => (
                                <input className='form-control' type='text' value={item[header.name]} onChange={e => setItemData([ index, header.name ], e.target.value)} />
                              ),
                              [`index_up_to|golfers_per_${category}`]: () => (
                                <div className='flex'>
                                  <input
                                    className='form-control'
                                    type='text'
                                    value={item[header.name]}
                                    onChange={e => {
                                      setItemData([index, header.name], e.target.value);
                                      if(category === 'division') {
                                        const inputValue = parseIndex(e.target.value);
                                        let localIndexes = JSON.parse(JSON.stringify(indexes));
                                    
                                        items.forEach((_item, _index) => {
                                          if (Array.isArray(_item.no_golfers)) {
                                            localIndexes.push(..._item.no_golfers);
                                          }
                                        });

                                        items.forEach((_item, _index) => {
                                          let itemNoGolfers
                                          const itemIndexValue = parseIndex(_item.index_up_to);
                                          item === _item ? 
                                            itemNoGolfers = localIndexes.filter(i => parseIndex(i.index) <= inputValue) :
                                            itemNoGolfers = localIndexes.filter(i => parseIndex(i.index) <= itemIndexValue)
                                          localIndexes = localIndexes.filter(index => !itemNoGolfers.some(golfer => golfer.index === index.index));
                                          setItemData([_index, 'no_golfers'], itemNoGolfers);
                                        }) 
                                        setIndex(localIndexes);
                                      }
                                    }}
                                    onBlur={e => {
                                      if (header.name === 'index_up_to') { return }
                                      const v = parseInt(e.target.value) || ''
                                      setItemData([ index, header.name ], v === '' ? v : Math.max(v, 0))
                                    }}
                                  />
                                  { withIndexUpTo && category === 'division' && fixedItems.dependencies[1] === 'combined_index_usga_index' &&
                                    <span>
                                      {item.no_golfers ? item.no_golfers.length : '0'} Golfers
                                    </span>
                                  }
                                </div>
                              ),
                              genders: () => (
                                <div>
                                  {
                                    Object.keys(item.genders).map(gender =>
                                      <Checkbox
                                        key={ `gender_${gender}_${category}_${index}` }
                                        label={gender}
                                        selected={item.genders[gender]}
                                        className={`${genderAnswers.length <= 1 ? 'disabled' : ''}`}
                                        setOption={(v) => {
                                          if (genderAnswers.length <= 1) { return }

                                          setItemData([ index, header.name, gender ], v)
                                          if (oneGender && genderAnswers.length > 1) {
                                            const otherGender = gender === 'M' ? 'F' : 'M'
                                            setItemData([ index, header.name, otherGender ], !v)
                                          }
                                        }}
                                      />
                                    )
                                  }
                                </div>
                              ),
                              age_range: () => (
                                <div>
                                  <input className='form-control' type='text' value={item[header.name].from} onChange={e => setItemData([ index, header.name, 'from' ], e.target.value)} />
                                  -
                                  <input className='form-control' type='text' value={item[header.name].to} onChange={e => setItemData([ index, header.name, 'to' ], e.target.value)} />
                                </div>
                              ),
                              course_and_tee: () => (
                                <>
                                  <div className='course_and_tee' >
                                    <GlgSelect
                                      key={item.course_and_tee.tee + `_${category}_${index}`}
                                      items={ Object.values(tees) }
                                      placeholder={ internationalize('dashboard_plus.divisions_flights.select_tee') }
                                      value={ item.course_and_tee.tee }
                                      noPreFill={true}
                                      onChange={ v => {
                                        setItemData([ index, header.name, 'tee' ], v)
                                        setItemData([ index, header.name, 'hole' ], Object.values(tees[v].holes)[0]?.value || '')
                                        setItemData([ index, header.name, 'virtual_id' ], Object.values(tees[v].holes)[0]?.virtual_id || '')
                                      }}
                                    />
                                  </div>

                                  <div className='hole' >
                                    <GlgSelect
                                      key={item.course_and_tee.tee + '_' + item.course_and_tee.hole + `_${category}_${index}`}
                                      items={ item.course_and_tee.tee ? Object.values(tees[item.course_and_tee.tee].holes) : [] }
                                      value={ item.course_and_tee.hole }
                                      onChange={ v => {
                                        setItemData([ index, header.name, 'hole' ], v)
                                        setItemData([ index, header.name, 'virtual_id' ], tees[item.course_and_tee.tee].holes[v]?.virtual_id)
                                      }}
                                      placeholder={ internationalize('dashboard_plus.divisions_flights.select_hole') }
                                    />
                                  </div>
                                </>
                              ),
                            })(header.name)()
                          }
                        </td>)
                      /* eslint-enable */
                    }
                  </tr>
                )
              })
          }
        </tbody>
      </table>
      <div className='divisions-flights-action-buttons'>
        <a
          className={ `action-button add-${category}-button` }
          href='javascript: void(0);'
          onClick={addItem}>
          + { internationalize('dashboard_plus.divisions_flights.add', { 
                  category: categoryLabel === 'Division' && fixedItems.type !== 'disabled' && golfersDivisionsFlights?.selected !== 'number_of_divisions' ? 
                            'Golfers' : 
                            categoryLabel })} 
        </a>
        <a
          className={ `action-button reset-${category}-button` }
          href='javascript: void(0);'
          onClick={resetItems}>
          { internationalize('dashboard_plus.divisions_flights.reset', { category: categoryLabel }) }
        </a>
      </div>
    </div>
  )
}

DivisionsFlightsTable.propTypes = {
  category: PropTypes.string,
  withIndexUpTo: PropTypes.bool,
  withNumberOfGolfers: PropTypes.bool,
  withGenders: PropTypes.bool,
  withAgeRange: PropTypes.bool,
  genderAnswers: PropTypes.array,
  oneGender: PropTypes.bool,
  tees: PropTypes.object,
  setCount: PropTypes.func,
  golfersDivisionsFlights: PropTypes.object,
  rowClass: PropTypes.string,
  indexes: PropTypes.array,
  items: PropTypes.array,
  fixedItems: PropTypes.shape({
    type: PropTypes.oneOf([ 'disabled', 'max_golfers', 'fixed' ]),
    total: PropTypes.number,
    value: PropTypes.number,
    dependencies: PropTypes.array,
  }),
  setItemData: PropTypes.func,
  setIndex: PropTypes.func,
  setError: PropTypes.func,
  errorCheckingDependencies: PropTypes.array,
}

DivisionsFlightsTable.defaultProps = {
  fixedItems: {
    type: 'disabled',
  },
}

export default DivisionsFlightsTable
