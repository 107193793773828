import React from 'react'
import PropTypes from 'prop-types'
import { GlobalProvider } from '../../shared/global_context'
import configureStore from '../../shared/configure_store'
import ScorecardsCenterContainer from './scorecards_center_container'
import scorecardsCenterReducer from '../reducers/index'

const ScorecardsCenterRoot = (initialState) => {

  const state = {
    scorecards_center: {
      customer_id: initialState.scorecards_center.customer_id,
      category_lists: initialState.scorecards_center.category_lists,
      report_center_lists: initialState.scorecards_center.report_center_lists,
      league_id: initialState.scorecards_center.league_id,
      round_id: initialState.scorecards_center.round_id,
      enable_discover: initialState.scorecards_center.enable_discover,
      is_team_captain: initialState.scorecards_center.is_team_captain,
      is_tm_lite: initialState.scorecards_center.is_tm_lite,
      canUseLegacyScorecards: initialState.scorecards_center.canUseLegacyScorecards,
    },

    popup: {
      do_not_show: initialState.popup.do_not_show,
      show_phase_out_popup: false,
      open_modal: false,
      url: '',
    },
  }

  const store = configureStore(scorecardsCenterReducer, state)

  return (
    <GlobalProvider store={store}>
      <ScorecardsCenterContainer/>
    </GlobalProvider>
  )
}

ScorecardsCenterRoot.propTypes = {
  initialState: PropTypes.any,
}

export default ScorecardsCenterRoot
