import {
  FETCH_DATA_REQUEST,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE,
} from '../actions/index'

const initialState = {
  rounds: null,
  matchesResults: null,
  parArray: [],
  dashboardType: null,
  loading: false,
  error: null,
}

const misc = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA_REQUEST:
      return {
        ...state,
        loading: action.payload.showLoading,
        error: null,
      }
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        matchesResults: action.payload.matchesResults,
        dashboardType: action.payload.dashboardType,
        parArray: action.payload.parArray,
        loading: false,
        error: null,
      }
    case FETCH_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

export default misc
