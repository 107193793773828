// Generic

export const NONE = 'NONE'
export const SUCCESS = 'SUCCESS'
export const ERROR = 'ERROR'
export const COMPLETE = 'complete'

export const BLUE_BOX_HOTZONE_VIEWPORT_WIDTH = 0.7
export const BLUE_BOX_HOTZONE_VIEWPORT_HEIGHT = 0.1

// Header

export const HEADER_VARS = [
  [ 'Event Name', '%event.name%' ],
  [ 'Tournament Name', '%tournament.name%' ],
  [ 'Division Name', '%division.name%' ],
  [ 'Round Date', '%round.date%', 'date', 'E.g., Tue, August 28' ],
]

export const MIN_HEADER_HEIGHT = 60
export const MAX_HEADER_HEIGHT = 120

// Footer

export const FOOTER_VARS = [
  [ 'Event Name', '%event.name%' ],
  [ 'Tournament Name', '%tournament.name%' ],
  [ 'Division Name', '%division.name%' ],
  [ 'Round Date', '%round.date%', 'date', 'E.g., Tue, August 28' ],
]

export const MIN_FOOTER_HEIGHT = 20
export const MAX_FOOTER_HEIGHT = 60

// Slides

export const LEADERBOARD_TYPE = 'leaderboard'
export const ADVANCED_TYPE = 'advanced'
export const PHOTOS_TYPE = 'photos'
export const VIDEO_TYPE = 'video'
export const PARIMUTUEL_TYPE = 'parimutuel'
export const TEXT_TYPE = 'text'
export const COLLAGE_TYPE = 'collage'
export const TEE_SHEET_TYPE = 'tee sheet'

export const LG_SLIDES_COL_COUNT = 6
export const MD_SLIDES_COL_COUNT = 4
export const SM_SLIDES_COL_COUNT = 3
export const XS_SLIDES_COL_COUNT = 2
export const XXS_SLIDES_COL_COUNT = 1

// Slideshow

export const LEADERBOARD_HEADER_SELECTOR = 'tr.header:not(:has(td.scope_name)):not(:has(td.cut_list_tr)):not(.section_line_header)'
export const MATCH_FORMAT_NODE_ID = 8

// Competition nodes

export const PLAYER_V_PLAYER_1V3_2V4_NODE_ID = 42
export const PLAYER_V_PLAYER_1V2_3V4_NODE_ID = 43
export const PLAYER_V_PLAYERS_1V3_1V4_2V3_2V4_NODE_ID = 44
export const PLAYER_V_ALL_1V2_1V3_1V4_2V3_2V4_3V4_NODE_ID = 45
export const TEAM_V_TEAM_1V3_1V4_2V3_2V4_NODE_ID = 47
export const PAIR_V_PAIR_1_AND_2_V_3_AND_4_NODE_ID = 49
export const PLAYER_V_FOURSOME_NODE_ID = 52

export const SCOPED_LEADERBOARD_COMPETITION_NODE_IDS = [ 
  PLAYER_V_PLAYER_1V3_2V4_NODE_ID,
  PLAYER_V_PLAYER_1V2_3V4_NODE_ID,
  PLAYER_V_PLAYERS_1V3_1V4_2V3_2V4_NODE_ID,
  PLAYER_V_ALL_1V2_1V3_1V4_2V3_2V4_3V4_NODE_ID,
  PAIR_V_PAIR_1_AND_2_V_3_AND_4_NODE_ID,
  PLAYER_V_FOURSOME_NODE_ID,
  TEAM_V_TEAM_1V3_1V4_2V3_2V4_NODE_ID,
]

export const MATCH_SCOPED_LEADERBOARD_COMPETITION_NODE_IDS = [
  TEAM_V_TEAM_1V3_1V4_2V3_2V4_NODE_ID,
  PLAYER_V_PLAYERS_1V3_1V4_2V3_2V4_NODE_ID,
]

export const ANIMATION_TIME = 5 // seconds

// Colors

export const USGA_SILVER = '#EBEBEB'
export const USGA_OLD_BLUE = '#00365F'
export const BLUE_GREY = '#647d96'
export const GREY = '#cccccc'
export const MOON_GREY = '#e6e6e6'
export const ALPINE_BLUE = '#1e4164'
