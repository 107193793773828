import React, { useState } from 'react'
import PropTypes from 'prop-types'

const getLuminance = (hexColor) => {
  let r = parseInt(hexColor.substring(1, 3), 16) / 255
  let g = parseInt(hexColor.substring(3, 5), 16) / 255
  let b = parseInt(hexColor.substring(5, 7), 16) / 255

  r = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4)
  g = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4)
  b = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4)

  return 0.2126 * r + 0.7152 * g + 0.0722 * b
}

const getFontColor = (hexColor) => {
  const luminance = getLuminance(hexColor)
  return luminance > 0.5 ? '#000' : '#FFF'
}

const getHoleClass = (par, score) => {
  const birdieScore = par
  const eagleScore = par - 1
  const plus1Score = par + 1

  if (score < eagleScore) {
    return 'double-circle'
  } else if (score < birdieScore && score >= eagleScore) {
    return 'simple-circle'
  } else if (score <= plus1Score && score > birdieScore) {
    return 'simple-square'
  } else if (score > plus1Score) {
    return 'double-square'
  }

  return ''  
}

const JrLeagueDashboardScramble = ({ matchesResults, parArray }) => {
  const [ expandedTeams, setExpandedTeams ] = useState({})

  const toggleExpand = (teamName) => {
    setExpandedTeams((prevState) => ({
      ...prevState,
      [teamName]: !prevState[teamName],
    }))
  }

  const expandAll = () => {
    const allExpanded = Object.keys(matchesResults).reduce((acc, teamName) => {
      acc[teamName] = true
      return acc
    }, {})
    setExpandedTeams(allExpanded)
  }

  const collapseAll = () => {
    const allCollapsed = Object.keys(matchesResults).reduce((acc, teamName) => {
      acc[teamName] = false
      return acc
    }, {})
    setExpandedTeams(allCollapsed)
  }

  if (!matchesResults || !parArray || Object.keys(matchesResults).length === 0) {
    return <div>Leaderboard is not created yet</div>
  }
  
  return (
    <div className="jrleague-scramble">
      <div className="button-container">
        <a onClick={expandAll}>Expand All</a>
        <a onClick={collapseAll}>Collapse All</a>
      </div>
      <table className="scramble-table">
        <thead>
          <tr>
            <th colSpan="10"></th>
            {matchesResults &&
              Object.keys(matchesResults).length > 0 &&
              Object.entries(matchesResults)[0][1].team_total_scores.map((round, index) => (
                <React.Fragment key={index}>
                    {round.thru !== 0 && (
                    <th className="scramble-round" key={`thru-${index}`}>
                        Thru
                    </th>
                    )}
                    <th className="scramble-round" key={`round-${index}`}>
                    {round.round_name}
                    </th>
                </React.Fragment>
              ))}
          </tr>
        </thead>
        <tbody>
          {Object.entries(matchesResults).map(([ teamName, teamData ]) => {
            const backgroundColor = teamData.color || '#FFF3CF'
            const fontColor = getFontColor(backgroundColor)

            return (
              <React.Fragment key={teamName}>
                <tr
                  className="team-row team-row-main "
                  onClick={() => toggleExpand(teamName)}
                  style={{ backgroundColor, color: fontColor }}
                >
                  <th className="left">
                    <span className="team-position">{teamData.position}</span>
                    {teamName}
                    <span className="team-arrow">{expandedTeams[teamName] ? '▲' : '▼'}</span>
                  </th>
                  <td colSpan="9"></td>
                  {teamData.team_total_scores.map((score, index) => (
                    <React.Fragment key={index}>
                      {score.thru !== 0 && (
                        <td key={`thru-${index}`}>{score.thru}</td>
                      )}
                      <td key={`score-${index}`}>{score.rank_value_display}</td>
                    </React.Fragment>
                  ))}
                </tr>

                {expandedTeams[teamName] && (
                  <>
                    <tr>
                    <th>Hole</th>
                    {teamData.holes === 'front9'
                        ? Array.from({ length: 9 }, (_, holeIndex) => (
                            <td key={holeIndex}>{holeIndex + 1}</td>
                            ))
                        : Array.from({ length: 9 }, (_, holeIndex) => (
                            <td key={holeIndex}>{holeIndex + 10}</td>
                            ))
                    }
                    <td colSpan={teamData.team_total_scores.length + teamData.in_progress_rounds}></td>
                    </tr>

                    <tr className="last-par-row">
                    <th>Par</th>
                    {teamData.holes === 'front9'
                        ? teamData.par_array.slice(0, 9).map((par, index) => (
                            <td key={index}>{par}</td>
                        ))
                        : teamData.par_array.slice(9, 18).map((par, index) => (
                            <td key={index + 9}>{par}</td>
                        ))}
                    <td colSpan={teamData.team_total_scores.length + teamData.in_progress_rounds}></td>
                    </tr>
                    {teamData.results.map((pair, index) => (
                      <tr
                        key={index}
                        className={`player-row ${
                          index === teamData.results.length - 1 ? 'last-player-row' : ''
                        }`}
                      >
                        <td>{pair.pair_name}</td>
                        {pair.scores.map((score, i) => {
                          const parArray = teamData.holes === 'front9' 
                            ? teamData.par_array.slice(0, 9)
                            : teamData.par_array.slice(9, 18)
                          const par = parArray[i]
                          const scoreClass = getHoleClass(par, score)

                          return (
                            <td key={i}>{score !== null ? <span className={scoreClass}>{score}</span> : ''}</td>
                          )
                        })}
                        <td>{pair.net_score}</td>
                        <td colSpan={teamData.team_total_scores.length + teamData.in_progress_rounds}></td>
                      </tr>
                    ))}
                  </>
                )}
              </React.Fragment>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

JrLeagueDashboardScramble.propTypes = {
  matchesResults: PropTypes.object,
  parArray: PropTypes.array,
}

export default JrLeagueDashboardScramble
