import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import DatePicker from 'react-datepicker'
import onClickOutside from 'react-onclickoutside'
import Loader from 'react-loader-spinner'
import { computeHour, computeMinutes } from './event_setup/time_helpers'
import { GlgSelect, GlgTooltip } from 'SharedComponents'
import _ from 'lodash'
import TimezoneDatePicker from './event_setup/timezone_date_picker'

class InPlaceEditableIncomplete extends Component {
  constructor(props){
    super(props)
    this.state = {
      open: false,
      value: props.value,
    }
    moment.tz.setDefault('Etc/UTC')
  }

  handleClickOutside = (event) => {
    if (this.state.open && event.target.nodeName !== 'INPUT') {
      this.setState({ open: false }, () => event.target.click())
      event.preventDefault()
      this.props.onEditCompleted(this.state.value)
    }
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.setState({ open: false })
      this.props.onEditCompleted(this.state.value)
    }
  }

  render() {
    return (
      this.state.open ?
        <input type='text' value={this.state.value} onChange={e => this.setState({ value: e.target.value})} onKeyPress={this.handleKeyPress} autoFocus />
        :
        <a onClick={() => this.setState({ open: true})}>{this.state.value}</a>
    )
  }
}

InPlaceEditableIncomplete.propTypes = {
  value: PropTypes.string,
  onEditCompleted: PropTypes.func,
}

InPlaceEditableIncomplete.defaultProps = {
  value: '',
  onEditCompleted: () => {},
}

const InPlaceEditable = onClickOutside(InPlaceEditableIncomplete)

class EventSetupRounds extends Component {
  constructor(props){
    super(props)
  }


  render() {
    const {
      title,
      header,
      rounds,
      lastPossibleDay,
      links,
      enabledDatepicker,
      updatingData,
      scheduleSignups,
      enableOTT,
      multipleUpdateActions,
      saveInProgress,
      lastRoundClickedIndex = null,
    } = this.props.content

    const selectableRounds = (action = multipleUpdateActions.value) => rounds.filter(r => action !== 'enable_tee' || r.signups_open.value !== 'na' )

    const internationalize = (key) => !window.I18n ? '' : window.I18n.t(key)
    links.update_play_at.path = decodeURI(links.update_play_at.path)

    return (
      <div className='widget-content-default widget-event-setup-rounds'>
        <div className='title'>
          {title}
        </div>

        <div className='content'>
          {
            rounds.length > 0 && multipleUpdateActions &&
            <div>
              <div className='actions_top'>
                <div className='actions_options'>
                  <label>{ multipleUpdateActions.label }</label>
                  <GlgSelect
                    items={ multipleUpdateActions.options }
                    value={ multipleUpdateActions.value }
                    onChange={ v => {
                      this.props.setData([ 'multipleUpdateActions', 'value' ], v)
                      _.difference(rounds, selectableRounds(v)).forEach(r => {
                        this.props.setData([ 'rounds', r.index - 1, 'selected' ], false)
                      })
                    }}
                  />
                </div>

                <div className='hidden-xs'>
                  <a onClick={ () => selectableRounds().forEach(r => { this.props.setData([ 'rounds', r.index - 1, 'selected' ], true) })}>
                    { internationalize('rounds.bootstrap.round_list.select_all') }
                  </a>
                  <span> | </span>
                  <a onClick={ () => selectableRounds().forEach(r => { this.props.setData([ 'rounds', r.index - 1, 'selected' ], false) })}>
                    { internationalize('rounds.bootstrap.round_list.clear_selection') }
                  </a>
                </div>
              </div>

              {
                multipleUpdateActions.value === 'schedule_registration' &&
                <div className='schedule_open_close_dates well margin_top_15 white_background'>
                  <fieldset className='form-inline'>
                    <div className='form-group'>
                      <label> { internationalize('rounds.bootstrap.round_list.schedule_opening_round') } </label>
                      <input
                        value={scheduleSignups.open.days_before}
                        onChange={ e => this.props.setData([ 'scheduleSignups', 'open', 'days_before' ], e.target.value) }
                      />

                      <label> { internationalize('rounds.bootstrap.round_list.days_before_round') } </label>
                      <input
                        value={scheduleSignups.open.hour}
                        onChange={ e => this.props.setData([ 'scheduleSignups', 'open', 'hour' ], e.target.value) }
                        onBlur={ e => {
                          const [ hour, amPm ] = computeHour(e.target.value)
                          this.props.setData([ 'scheduleSignups', 'open', 'hour' ], hour)
                          this.props.setData([ 'scheduleSignups', 'open', 'am_pm' ], amPm)
                        }}
                      />

                      <label> : </label>
                      <input
                        value={scheduleSignups.open.min}
                        onChange={ e => this.props.setData([ 'scheduleSignups', 'open', 'min' ], e.target.value) }
                        onBlur={ e => {
                          this.props.setData([ 'scheduleSignups', 'open', 'min' ], computeMinutes(e.target.value))
                        }}
                      />

                      <a
                        className='am_pm'
                        onClick={ () => this.props.setData([ 'scheduleSignups', 'open', 'am_pm' ], scheduleSignups.open.am_pm === 'AM' ? 'PM' : 'AM' )}>
                        { scheduleSignups.open.am_pm}
                      </a>
                    </div>
                  </fieldset>

                  <br/>

                  <fieldset className='form-inline'>
                    <div className='form-group'>
                      <label> { internationalize('rounds.bootstrap.round_list.schedule_closing_round') } </label>
                      <input
                        value={scheduleSignups.close.days_before}
                        onChange={ e => this.props.setData([ 'scheduleSignups', 'close', 'days_before' ], e.target.value) }
                      />

                      <label> { internationalize('rounds.bootstrap.round_list.days_before_round') } </label>
                      <input
                        value={scheduleSignups.close.hour}
                        onChange={ e => this.props.setData([ 'scheduleSignups', 'close', 'hour' ], e.target.value) }
                        onBlur={ e => {
                          const [ hour, amPm ] = computeHour(e.target.value)
                          this.props.setData([ 'scheduleSignups', 'close', 'hour' ], hour)
                          this.props.setData([ 'scheduleSignups', 'close', 'am_pm' ], amPm)
                        }}
                      />

                      <label> : </label>
                      <input
                        value={scheduleSignups.close.min}
                        onChange={ e => this.props.setData([ 'scheduleSignups', 'close', 'min' ], e.target.value) }
                        onBlur={ e => {
                          this.props.setData([ 'scheduleSignups', 'close', 'min' ], computeMinutes(e.target.value))
                        }}
                      />

                      <a
                        className='am_pm'
                        onClick={ () => this.props.setData([ 'scheduleSignups', 'close', 'am_pm' ], scheduleSignups.close.am_pm === 'AM' ? 'PM' : 'AM' )}>
                        { scheduleSignups.close.am_pm}
                      </a>
                    </div>
                  </fieldset>
                </div>
              }

              {
                multipleUpdateActions.value === 'enable_tee' &&
                  <div className='enable_open_tee_times well margin_top_15 white_background'>
                    <div className='reserved_tee_times_container'>
                      <label> { internationalize('rounds.tabs.tabs1.how_many') } </label>
                      <input
                        className='form-control'
                        type='number'
                        value={enableOTT.reserved_tee_times}
                        onChange={ e => this.props.setData([ 'enableOTT', 'reserved_tee_times' ], e.target.value) }
                        onBlur={ e => this.props.setData([ 'enableOTT', 'reserved_tee_times' ], Math.max(1, e.target.value)) }
                      />
                    </div>

                    <label className='icheck-label' htmlFor='hide_players_on_tee_times'>
                      <div className={`icheckbox_square-orange icheck-item ${enableOTT.hide_players_on_tee_times ? 'checked' : ''}`}>
                        <input
                          id='hide_players_on_tee_times'
                          className='i-checks icheck-input'
                          type='checkbox'
                          value={enableOTT.hide_players_on_tee_times}
                          checked={enableOTT.hide_players_on_tee_times}
                          onChange={event => this.props.setData([ 'enableOTT', 'hide_players_on_tee_times' ], event.target.checked)}
                        />
                      </div>
                      <span>{internationalize('rounds.tabs.tabs1.hide_name')}</span>
                    </label>

                    <label className='icheck-label' htmlFor='ott_ggid_read_only'>
                      <div className={`icheckbox_square-orange icheck-item ${enableOTT.ott_ggid_read_only ? 'checked' : ''}`}>
                        <input
                          id='ott_ggid_read_only'
                          className='i-checks icheck-input'
                          type='checkbox'
                          value={enableOTT.ott_ggid_read_only}
                          checked={enableOTT.ott_ggid_read_only}
                          onChange={event => this.props.setData([ 'enableOTT', 'ott_ggid_read_only' ], event.target.checked)}
                        />
                      </div>
                      <span>{internationalize('rounds.tabs.tabs1.users_logged_in')}</span>
                    </label>
                  </div>
              }
            </div>
          }

          <div className='rounds_tabular table-responsive'>
            <table className='rounds_table table table-striped'>
              <thead>
                <tr className='header'>
                  {
                    header.map((headerText, index) =>
                      <th key={ `${headerText}-${index}` }>{ headerText }</th>)
                  }
                </tr>
              </thead>

              <tbody>
                {
                  rounds.map((round, idx) =>
                    <tr key={`${round.id}-${round.name}-${round.ggid}`}>
                      <td>
                        <InPlaceEditable
                          value={round.name}
                          onEditCompleted={(name) => name !== round.name && this.props.updateRoundName(round.id, name)}
                        />
                      </td>
                      <td>
                        <label className='font-weight-normal'>{round.ggid}</label>
                      </td>
                      <td className={`datepicker ${enabledDatepicker && enabledDatepicker === round.id ? 'datepicker-open' : ''}`}>
                        {
                          enabledDatepicker && enabledDatepicker === round.id ?
                            <TimezoneDatePicker
                              selected={round.play_at}
                              showTimeSelect={false}
                              targetTimezone='UTC'
                              onCalendarClose={() => this.props.setData([ 'enabledDatepicker' ], false)}
                              onChange={(date) => this.props.updateRoundDate(round.id, date)}
                              onKeyDown={(e) => { e.preventDefault() }}
                              showPopperArrow={false}
                              wrapperClassName='wide-datepicker-input'
                              popperClassName='wide-datepicker'
                              startOpen
                            />
                            :
                            <a onClick={() => this.props.setData([ 'enabledDatepicker' ], round.id)}>
                              <i className='gga-set-up-your-events-date'> </i>
                              {moment
                                  .unix((round.play_at))
                                  .tz('UTC')
                                  .locale(window.I18n.locale === 'en' ? 'en-GB' : window.I18n.locale)
                                  .format(' LL')}
                            </a>
                        }
                      </td>
                      {
                        'signups_open' in round &&
                        <td className='signups'>
                          {
                            round.signups_open.value === 'na' ?
                              <label className='font-weight-normal'> { round.signups_open.labels[round.signups_open.value] } </label>
                            :
                            <label
                              className={ `${ updatingData[round.id] === 'toggleSignups' ? 'loading' : 'toggle'} label label-${round.signups_open.value === 'yes' ? 'success' : 'warning'}` }
                              onClick={ () => this.props.toggleSignups(round.id) } >
                              { round.signups_open.labels[round.signups_open.value] }
                            </label>
                          }
                        </td>
                      }
                      <td>
                        <a
                          className={`${ updatingData[round.id] === 'removeRound' ? 'disabled' : ''}`}
                          onClick={() => this.props.removeRound(round.id)}>
                          {links.remove_round.name}
                        </a>
                      </td>

                      {
                        multipleUpdateActions &&
                          <td className='select-rounds'>
                            <div>
                              {
                                ((round) => {
                                  const shouldDisableCheckbox = multipleUpdateActions.value === 'enable_tee' && round.signups_open.value === 'na'

                                  const checkbox = (
                                    <div
                                      className={`icheckbox_square-orange icheck-item ${round.selected ? 'checked' : ''} ${shouldDisableCheckbox ? 'disabled' : ''}`}
                                      onClick={ e => {
                                        const selectCurrentRound = () => shouldDisableCheckbox || this.props.setData([ 'rounds', idx, 'selected' ], !round.selected)

                                        if (! e.shiftKey) {
                                          selectCurrentRound()
                                          this.props.setData([ 'lastRoundClickedIndex' ], null)
                                        } else if (lastRoundClickedIndex === null) {
                                          selectCurrentRound()
                                          this.props.setData([ 'lastRoundClickedIndex' ], idx)
                                        } else {
                                          const low = Math.min(idx, lastRoundClickedIndex)
                                          const high = Math.max(idx, lastRoundClickedIndex)

                                          for (let i = low; i <= high; ++i) {
                                            if (selectableRounds().includes(rounds[i])) {
                                              this.props.setData([ 'rounds', i, 'selected' ], rounds[lastRoundClickedIndex].selected)
                                            }
                                          }
                                          this.props.setData([ 'lastRoundClickedIndex' ], null)
                                        }
                                      }}
                                    />
                                  )

                                  return shouldDisableCheckbox ? <GlgTooltip tooltip={internationalize('rounds.tabs.tabs1.past_rounds')}>{checkbox}</GlgTooltip> : checkbox
                                })(round)
                              }
                            </div>
                          </td>
                      }
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>
          <div className='save-buttons-row'>
            {
              enabledDatepicker && enabledDatepicker === 'AddRounds' ?
                <DatePicker
                  selected={new Date()}
                  dateFormat='d MMMM yyyy'
                  onCalendarClose={() => this.props.setData([ 'enabledDatepicker' ], false)}
                  onChange={(date) => this.props.addRound(moment(date).format('DD/MM/YYYY'))}
                  onKeyDown={(e) => { e.preventDefault() }}
                  minDate={new Date()}
                  maxDate={moment.unix(lastPossibleDay).toDate()}
                  startOpen
                />
                :
                (
                  updatingData.adding_round ?
                    <div className='add-round-loader'>
                      <Loader type="Oval" color={ '#00A0CE' } height={ 40 } width={ 40 } />
                    </div>
                    :
                    <a
                      className='save-button'
                      onClick={() => this.props.setData([ 'enabledDatepicker' ], 'AddRounds')}>
                      + {links.add_round.name}
                    </a>
                )

            }

            {
              rounds.length > 0 && rounds.some(r => r.selected) &&
                <a onClick={saveInProgress ? undefined : this.props.doAction} className={`save-button widget-white-title-button ${saveInProgress ? 'disabled' : ''}`}>
                  {
                    saveInProgress ?
                      <Loader type="ThreeDots" color={ 'black' } height={ 20 } width={ 30 } /> :
                      internationalize('rounds.bootstrap.round_list.go')
                  }
                </a>
            }
          </div>
        </div>
      </div>
    )
  }
}

EventSetupRounds.propTypes = {
  content: PropTypes.object,
  setData: PropTypes.func,
  addRound: PropTypes.func,
  removeRound: PropTypes.func,
  updateRoundDate: PropTypes.func,
  updateRoundName: PropTypes.func,
  toggleSignups: PropTypes.func,
  doAction: PropTypes.func,
}

EventSetupRounds.defaultProps = {
  content: {
    title: 'ROUNDS',
    header: [],
    rounds: [],
    links: {
      add_round: { name: 'Add rounds', path: '#' },
      remove_round: { name: 'Remove', path: '#' },
      update_play_at: { path: '#' },
      update_round: { path: '#' },
      toggle_signups: { path: '#' },
    },
    enabledDatepicker: false,
    show_widget: true,
  },
}

export default EventSetupRounds
