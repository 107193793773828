import React from 'react'
import PropTypes from 'prop-types'
import {Button, Modal} from 'react-bootstrap'

const ModalPopup = ({openModal, onOkClicked, saveInProgress}) => {
  return (
    <Modal className="draft_popup" show={openModal} popup="true">
      <Modal.Header>
        <Modal.Title className="text-center"
                     dangerouslySetInnerHTML={{__html: window.I18n.t('draft.components.manager_side.modal_popup.draft_is_completed')}}/>
      </Modal.Header>
      <Modal.Footer className="popup_buttons">
        <Button type="button" className="btn button btsp_orange_button" onClick={onOkClicked} disabled={saveInProgress}>
          {window.I18n.t(`buttons.${saveInProgress ? 'redirecting' : 'ok'}`)}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

ModalPopup.propTypes = {
  openModal: PropTypes.bool,
  onOkClicked: PropTypes.func,
  saveInProgress: PropTypes.bool,
}

export default ModalPopup
