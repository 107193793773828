import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'

import TeeData from './tee_data_component'
import FoursomeData from './foursome_data_component'

class ShotByShotTableComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hideThead: true,
    }
    this.toggleThead = this.toggleThead.bind(this)
    this.theadHeightRef = createRef()
  }

  componentDidMount() {
    const theadElement = document.querySelector('.shot_trackings_table .shot_trackings thead')
    if (theadElement) {
      this.theadHeightRef.current = theadElement.offsetHeight
    }
  }

  componentDidUpdate() {
    $('.shot_trackings_table').perfectScrollbar('update')
  }

  toggleThead() {
    this.setState(prevState => ({
      hideThead: !prevState.hideThead,
    }))
    const theadsOverflow = document.querySelector('.floatThead-container .shot_trackings thead')
    for (let i = 1; i < theadsOverflow.children.length; i++) {
      theadsOverflow.children[i].style.display = this.state.hideThead ? 'none' : 'table-row'
    }
    const theadTableRow = document.querySelector('.shot_trackings_table .shot_trackings thead tr')
    const newHeight = theadsOverflow.children[0].offsetHeight
    if (this.state.hideThead) {
      for (let i = 0; i < theadTableRow.children.length; i++) {
        theadTableRow.children[i].style.height = newHeight + 'px'
      }
      theadTableRow.style.height = newHeight + 'px'
    } else {
      for (let i = 0; i < theadTableRow.children.length; i++) {
        theadTableRow.children[i].style.height = this.theadHeightRef.current + 'px'
      }
      theadTableRow.style.height = this.theadHeightRef.current + 'px'
    }
    if (this.state.hideThead) {
      const psScrollbarYRail = document.querySelector('.shot_trackings_table .ps-scrollbar-y-rail')
      if (psScrollbarYRail) {
        psScrollbarYRail.style.marginTop = newHeight + 'px'
      }
    } else {
      const psScrollbarYRail = document.querySelector('.shot_trackings_table .ps-scrollbar-y-rail')
      if (psScrollbarYRail) {
        psScrollbarYRail.style.marginTop = this.theadHeightRef.current + 'px'
      }
    }
  }

  render() {
    const tees = { ...this.props.tees }
    const foursomes = [ ...this.props.foursomes ]

    return (
      <Col xs={12} sm={12} md={12} lg={12} className='no-padding'>
        <div className='shot_trackings_table btsp_ps_container btsp_fheader_table_container'>
          <table className='shot_trackings btsp_fheader_table'>
            <thead> 
              <TeeData 
                tees={tees}
                isMultiRound={this.props.isMultiRound}
                hasFourballTournament={this.props.hasFourballTournament}
                toggleThead={this.toggleThead}
                hasFourballTournament={this.props.hasFourballTournament}
              />
            </thead>
            <tbody>
              {
                foursomes.map( foursome => 
                  <FoursomeData 
                    key={foursome.id}
                    foursome={foursome}
                    isMultiRound={this.props.isMultiRound}
                    hasFourballTournament={this.props.hasFourballTournament}/>
                )
              }
            </tbody>
          </table>
        </div>
      </Col>
    )
  }
}

ShotByShotTableComponent.propTypes = {
  tees: PropTypes.object,
  foursomes: PropTypes.array,
  isMultiRound: PropTypes.bool,
  togglePopup: PropTypes.func,
  hasFourballTournament: PropTypes.bool,
}

export default ShotByShotTableComponent
