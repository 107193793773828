export const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST'
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS'
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE'

export const fetchData = (roundId, refreshUrl, showLoading = true) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_DATA_REQUEST, payload: { showLoading } })

    try {
      const response = await fetch(`${refreshUrl}?roundId=${roundId}`)
      const data = await response.json()

      dispatch({
        type: FETCH_DATA_SUCCESS,
        payload: {
          matchesResults: data.matchResults,
          dashboardType: data.dashboardType,
          parArray: data.parArray,
        },
      })
    } catch (error) {
      dispatch({
        type: FETCH_DATA_FAILURE,
        error: error.message,
      })
    }
  }
}
