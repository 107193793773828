import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { GlgSelect, GlgTooltip } from 'SharedComponents'
import LabeledInput from './event_setup/labeled_input'
import CalendarCustomInput from './event_setup/calendar_custom_input'
import RadioGroup from './event_setup/radio_group'
import DivisionsFlightsTable from './event_setup/divisions_flights_table'

const internationalize = (...args) => !window.I18n ? '' : window.I18n.t(...args)

class EventSetupFlights extends Component {
  constructor(props){
    super(props)
  }


  render() {
    const getDateFormat = format => {
      if (format === 'YMD')
        return 'yyyy/MM/dd h:mm aa'
      else if (format === 'DMY')
        return 'dd/MM/yyyy h:mm aa'
      else
        return 'MM/dd/yyyy h:mm aa'
    }

    const { divisions, tees, title, dataChanged, saveInProgress, links } = this.props.content
    const createFlightsBy = this.props.content.create_flights_by
    const autoFillBy = this.props.content.auto_fill_by
    const byHandicap = autoFillBy.selected === 'course_handicap' || autoFillBy.selected.startsWith('combined_index')
    const flightsNumberOf = this.props.content.flights_number_of
    const dateForAge = this.props.content.date_for_age
    const dateFormat = getDateFormat(this.props.content.date_format)
    const divideFlightsBy = this.props.content.divide_by
    const customFields = this.props.content.division_custom_fields[divisions.selected]
    const genderAnswers = this.props.content.division_gender_answers[divisions.selected].gender_answers || []
    const availableMembersCount = this.props.content.division_available_members_count[divisions.selected]
    const flights = this.props.content.flights || []
    const setFlightData = (keys, value) => { this.props.setData([ 'flights' ].concat(keys), value) }

    const errors = Object.values(this.props.content.errors || {}).filter(x => x)

    return (
      <div className='widget-content-default widget-event-setup-flights'>
        <div className='title'>
          { title }

          {
            dataChanged &&
              (() => {
                const saveButton = (
                  <a
                    onClick={saveInProgress || errors.length > 0 ? undefined : this.props.save}
                    className='btn btn-primary widget-white-title-button'
                    disabled={errors.length > 0}
                  >
                    { saveInProgress ? internationalize('dashboard_plus.widgets.saving') : links.save.name }
                  </a>
                )

                return errors.length > 0 ? <GlgTooltip tooltip={errors.join('\n')}>{saveButton}</GlgTooltip> : saveButton
              })()
          }
        </div>

        <div className='content flex-list-layout'>
          <ul>
            <li id='create_flight_for'>
              <LabeledInput label={'Create flight for'} childrenClass='narrow' spacing='10px'>
                <GlgSelect
                  items={ divisions.choices }
                  placeholder={ internationalize('dashboard_plus.event_setup_flights.choose_division') }
                  value={ divisions.selected }
                  onChange={ divisionId => {
                    this.props.setData([ 'divisions', 'selected' ], divisionId)

                    const newCustomFields = this.props.content.division_custom_fields[divisionId]
                    this.props.setData([ 'create_flights_by', 'selected' ], 'auto_fill')
                    this.props.setData([ 'create_flights_by', 'choices', 1, 'enabled' ], newCustomFields.custom_fields.length > 0)
                    this.props.setData([ 'create_flights_by', 'choices', 1, 'tooltip' ], newCustomFields.custom_fields.length > 0 ?
                      internationalize('flights.index.form_flight.flights_creation') :
                      newCustomFields.disabled_message
                    )

                    const newGenderAnswers = this.props.content.division_gender_answers[divisionId]
                    this.props.setData([ 'auto_fill_by', 'selected' ], 'course_handicap')
                    this.props.setData([ 'auto_fill_by', 'choices', autoFillBy.choices.length - 1, 'enabled' ], newGenderAnswers.enabled)
                    this.props.setData([ 'auto_fill_by', 'choices', autoFillBy.choices.length - 1, 'tooltip' ], newGenderAnswers.tooltip)

                    setFlightData([], undefined)
                  }}
                />
              </LabeledInput>
            </li>

            <li>
              <RadioGroup
                id='create_flights_by'
                {...createFlightsBy}
                setOption={ createBy => { this.props.setData([ 'create_flights_by', 'selected' ], createBy) }}
              />
            </li>

            {
              createFlightsBy.selected === 'auto_fill' &&
                <>
                  <li>
                    <RadioGroup
                      id='auto_fill_by'
                      {...autoFillBy}
                      setOption={ v => this.props.setData([ 'auto_fill_by', 'selected' ], v)}
                    />
                  </li>

                  {
                    byHandicap &&
                      <li>
                        <label>{ internationalize('flights.index.auto_fill_singles.number_of_flights') }</label>
                        <div className='number_of_golfers_flights_container'>
                          {
                            Object.keys(flightsNumberOf.choices).map((choiceKey, index) => {
                              const selectCountType = (countType) => { this.props.setData([ 'flights_number_of', 'selected' ], countType) }
                              const setCount = (value) => {
                                const countType = flightsNumberOf.selected
                                this.props.setData([ 'flights_number_of', 'choices', countType, 'value' ], value)
                              }

                              const choice = flightsNumberOf.choices[choiceKey]
                              return (
                                <div key={`golfers_flights_${index}`}>
                                  <label className={`icheck-label ${choice.enabled ? '' : 'disabled'}`} htmlFor={`golfers_flights_${index}`}>
                                    <div className={`iradio_square-orange icheck-item ${choice.enabled ? '' : 'disabled'} ${choiceKey === flightsNumberOf.selected ? 'checked' : ''}`}>
                                      <input
                                        id={`golfers_flights_${index}`}
                                        className='i-checks icheck-input'
                                        type='radio'
                                        name={`golfers_flights_${index}`}
                                        value={choiceKey}
                                        onChange={ event => choice.enabled && selectCountType(event.target.value) }
                                        checked={choiceKey === flightsNumberOf.selected}
                                        disabled={! choice.enabled}
                                      />
                                    </div>
                                    <span>{choice.label}</span>
                                  </label>
                                  <input
                                    className={`form-control ${choiceKey === flightsNumberOf.selected ? '' : 'disabled'}`}
                                    value={ choice.value }
                                    onChange={ e => setCount(e.target.value) }
                                    onBlur={ e => {
                                      const count = Math.max(0, parseInt(e.target.value) || 0)
                                      setCount(count)
                                    }}
                                    disabled={ choiceKey !== flightsNumberOf.selected }
                                  />
                                </div>
                              )
                            })
                          }
                        </div>
                      </li>
                  }

                  {
                    autoFillBy.selected === 'age_gender' &&
                      <li className='age_gender_li'>
                        <div className='date_to_calculate_age'>
                          <label>{ internationalize('divisions.index.age_gender.date_to_calculate_age') }</label>
                          <DatePicker
                            customInput={<CalendarCustomInput />}
                            selected={ dateForAge && moment.unix(dateForAge).toDate() }
                            dateFormat={ dateFormat }
                            onChange={(date) => {
                              date = date ? moment(date).unix() : date
                              this.props.setData([ 'date_for_age' ], date)
                            }}
                          />
                        </div>


                        <RadioGroup
                          id='divide_flights_by'
                          {...divideFlightsBy}
                          setOption={ v => this.props.setData([ 'divide_by', 'selected' ], v)}
                        />
                      </li>
                  }
                </>
            }

            {
              createFlightsBy.selected === 'custom_field' &&
                <div className='select_custom_field'>
                  <label>{ internationalize('divisions.index.add_new_division_or_refill.choose_custom_field') }</label>
                  <GlgSelect
                    key='select_custom_field'
                    items={ customFields.custom_fields }
                    placeholder='Custom field'
                    value={ customFields.selected }
                    noPreFill={true}
                    onChange={ v => { this.props.setData([ 'division_custom_fields', divisions.selected, 'selected' ], v) }}
                  />
                </div>
            }

            {
              createFlightsBy.selected !== 'custom_field' &&
                <DivisionsFlightsTable
                  category='flight'
                  withIndexUpTo={
                    createFlightsBy.selected === 'auto_fill' && byHandicap && flightsNumberOf.selected === 'flights'
                  }
                  withNumberOfGolfers={
                    createFlightsBy.selected === 'auto_fill' &&
                      (
                        (byHandicap && flightsNumberOf.selected === 'golfers') ||
                        (autoFillBy.selected === 'age_gender' && divideFlightsBy.selected === 'flights_number')
                      )
                  }
                  withGenders={ createFlightsBy.selected === 'auto_fill' && autoFillBy.selected === 'age_gender' }
                  withAgeRange={ createFlightsBy.selected === 'auto_fill' && autoFillBy.selected === 'age_gender' }
                  fixedItems={{
                    type: (createFlightsBy.selected === 'auto_fill' && byHandicap) ? (flightsNumberOf.selected === 'flights' ? 'fixed' : 'max_golfers') : 'disabled',
                    total: availableMembersCount,
                    value: parseInt(flightsNumberOf.choices[flightsNumberOf.selected].value) || 0,
                    dependencies: [ divisions, flightsNumberOf, createFlightsBy, autoFillBy ],
                  }}
                  genderAnswers={genderAnswers}
                  oneGender={true}
                  tees={tees}
                  items={flights}
                  setItemData={setFlightData}
                  setError={this.props.setError}
                  errorCheckingDependencies={[ createFlightsBy.selected, autoFillBy.selected, flightsNumberOf.selected, this.props.content.flights ]}
                  rowClass={createFlightsBy.selected}
                  setCount={(value) => {
                    const countType = flightsNumberOf.selected
                    this.props.setData([ 'flights_number_of', 'choices', countType, 'value' ], value)
                  }}
                  golfersDivisionsFlights={flightsNumberOf}
                />
            }
          </ul>
        </div>
      </div>
    )
  }
}

EventSetupFlights.propTypes = {
  content: PropTypes.object,
  setData: PropTypes.func,
  setError: PropTypes.func,
  save: PropTypes.func,
}

EventSetupFlights.defaultProps = {
  content: {
    title: 'EVENT FLIGHTS',
    data: {},
  },
}

export default EventSetupFlights
