import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import IframeResizer from 'iframe-resizer-react'

const StyledPage = styled.div`
  .name-input {
    width: 400px;
  }
`

const IframeContainer = (props) => {
  const iframeRef = useRef(null)
  const [ iframeLoaded, setIframeLoaded ] = useState(false)

  useEffect(() => {
    let bannerPreview = null
    if (props.formData.course_album === 'new') {
      bannerPreview = props.newBannersUpdated
    } else if (props.formData.course_album !== 'none' ) {
      bannerPreview = props.formData.course_album
    }

    let sponsorsPreview = { photos: [] }
    if (props.formData.sponsor === 'new') {
      sponsorsPreview = props.newSponsorsUpdated
    } else if (props.formData.sponsor !== 'none' ) {
      sponsorsPreview = props.formData.sponsor
    }

    let headerLogoPreviewUrl = null
    if (props.formData.header_logo === 'new') {
      headerLogoPreviewUrl = props.newHeaderLogosUpdated?.photos?.[0]?.header_url
    } else if (props.formData.header_logo !== 'none' ) {
      headerLogoPreviewUrl = props.formData.header_logo?.photos?.[0]?.header_url
    }

    let customGalleryPreviewUrls = []
    if (props.formData.custom_gallery === 'new') {
      customGalleryPreviewUrls = props.newCustomGalleryUpdated?.filter((photo) => !photo.image.includes('blob')).map((photo) => photo.image)
    } else {
      customGalleryPreviewUrls = props.formData.custom_gallery_photos?.photos?.map((photo) => photo.logo_url)
    }

    const hash = {
      description: props.formData.description,
      external_website: props.formData.external_website,
      sponsors: sponsorsPreview,
      course_album: bannerPreview,
      courses: (props.formData.selected_course === null || props.formData.selected_course === '-1') ? [] : [ props.formData.selected_course ],
      events: props.customClubLeagues && props.clubLeagues !== null ? props.clubLeagues || [] : null,
      events_count: props.eventsCount,
      events_location_data: props.eventsLocationData || [],
      filtered_by_event_type: props.filteredByEventType,
      aggregated: props.aggregated,
      filters: {...props.filters, hub_view: props.previewTab},
      customization_settings: {
        header_color: props.formData.theme_color,
        header_logo_url: headerLogoPreviewUrl,
        primary_color: props.formData.theme_color,
      },
      custom_title: props.formData.custom_title,
      use_custom_gallery: props.formData.use_custom_gallery,
      custom_gallery_preview_urls: customGalleryPreviewUrls,
    }

    if (iframeLoaded) {
      iframeRef.current.sendMessage(hash)
    } else {
      window.setTimeout(() => {
        iframeRef.current.sendMessage(hash)
      }, 1000)
    }
  }, [ props.formData, props.newBannersUpdated, props.newBannersUpdated.photos, props.newSponsorsUpdated, props.newSponsorsUpdated.photos, props.clubLeagues, props.filteredByEventType ])

  const sendInitialHash = () => {
    const location = [ props.data.location.street, props.data.location.city, props.data.location.state, props.data.location.zip_code ].filter((el) => el && el !== '').join(', ')

    let bannerPreview = null
    if (props.formData.course_album === 'new') {
      bannerPreview = props.newBannersUpdated
    } else if (props.formData.course_album !== 'none' ) {
      bannerPreview = props.formData.course_album
    }

    let sponsorsPreview = { photos: [] }
    if (props.formData.sponsor === 'new') {
      sponsorsPreview = props.newSponsorsUpdated
    } else if (props.formData.sponsor !== 'none' ) {
      sponsorsPreview = props.formData.sponsor
    }

    let headerLogoPreviewUrl = null
    if (props.formData.header_logo === 'new') {
      headerLogoPreviewUrl = props.newHeaderLogosUpdated?.photos?.[0]?.header_url
    } else if (props.formData.header_logo !== 'none' ) {
      headerLogoPreviewUrl = props.formData.header_logo?.photos?.[0]?.header_url
    }

    let customGalleryPreviewUrls = []
    if (props.formData.custom_gallery === 'new') {
      customGalleryPreviewUrls = props.newCustomGalleryUpdated?.photos?.map((photo) => photo.logo_url || photo.website_url)
    } else {
      customGalleryPreviewUrls = props.formData.custom_gallery_photos?.photos?.map((photo) => photo.logo_url)
    }

    const initializerHash = {
      customer_id: props.data.encrypted_id,
      name: props.data.name,
      description: props.formData.description,
      external_website: props.formData.external_website,
      location: location,
      courses: (props.formData.selected_course === null || props.formData.selected_course === '-1') ? [] : [ props.formData.selected_course ],
      sponsors: sponsorsPreview,
      course_album: bannerPreview,
      events: props.customClubLeagues && props.clubLeagues !== null ? props.clubLeagues || [] : null,
      events_location_data: props.eventsLocationData || [],
      events_count: props.eventsCount,
      filtered_by_event_type: props.filteredByEventType,
      aggregated: props.aggregated,
      filters: {...props.filters, hub_view: props.previewTab},
      customization_settings: {
        header_color: props.formData.theme_color,
        header_logo_url: headerLogoPreviewUrl,
        primary_color: props.formData.theme_color,
      },
      custom_title: props.formData.custom_title,
      use_custom_gallery: props.formData.use_custom_gallery,
      custom_gallery_preview_urls: customGalleryPreviewUrls,
    }

    window.setTimeout(() => {
      setIframeLoaded(true)
      iframeRef.current.sendMessage(initializerHash)
    }, 1000)
  }

  const transformScale = window.innerWidth / 2 / 1400

  return (
    <StyledPage>
      <div style={{ transform: `scale(${transformScale})`, transformOrigin: 'top left' }}>
        <IframeResizer
          forwardRef={iframeRef}
          log
          heightCalculationMethod='max'
          scrolling='no'
          src={props.src}
          style={{ width: '1280px', height: '1000px' }}
          onInit={sendInitialHash}
        />
      </div>
    </StyledPage>
  )
}

IframeContainer.propTypes = {
  src: PropTypes.string,
  data: PropTypes.object,
  eventsLocationData: PropTypes.object,
  formData: PropTypes.object,
  newBannersUpdated: PropTypes.object,
  newSponsorsUpdated: PropTypes.object,
  newHeaderLogosUpdated: PropTypes.object,
  newCustomGalleryUpdated: PropTypes.object,
  clubLeagues: PropTypes.array,
  customClubLeagues: PropTypes.bool,
  eventsCount: PropTypes.number,
  filteredByEventType: PropTypes.array,
  aggregated: PropTypes.bool,
  filters: PropTypes.object,
  previewTab: PropTypes.string,
}

export default IframeContainer
