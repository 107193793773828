import {showErrorNotification, showNotification} from '../../shared/helpers'

export const ActionTypes = {
  MOVE_TO_NOW_PICKING: 'MOVE_TO_NOW_PICKING',
  REMOVE_FROM_NOW_PICKING: 'REMOVE_FROM_NOW_PICKING',
  DRAFT_GOLFER: 'DRAFT_GOLFER',
  UNDO_LAST_PICK: 'UNDO_LAST_PICK',
  SAVE_IN_PROGRESS: 'SAVE_IN_PROGRESS',
}

export const moveToNowPicking = (golfer) => (dispatch) => {
  dispatch({
    type: ActionTypes.MOVE_TO_NOW_PICKING,
    data: golfer,
  })
}

export const removeFromNowPicking = () => (dispatch) => {
  dispatch({type: ActionTypes.REMOVE_FROM_NOW_PICKING})
}

export const autoPick = () => (dispatch, getState) => {
  dispatch({type: ActionTypes.SAVE_IN_PROGRESS})
  dispatch({type: ActionTypes.REMOVE_FROM_NOW_PICKING})

  const state = getState()
  const availableGolfers = state.draftManagerSide.availableGolfers
  const picks = state.draftManagerSide.picks
  let position = state.draftManagerSide.pickingPos
  const url = state.draftManagerSide.autoPickUrl
  const formData = new FormData()

  for (let i = 0; i < availableGolfers.length; i++) {
    formData.append('member_team_data[]', JSON.stringify({team_id: picks[position].teamId, member_id: availableGolfers[i].id}))
    position++
  }

  $.ajax({
    url: url,
    type: 'POST',
    data: formData,
    processData: false,
    contentType: false,
    success: () => {
      showNotification(window.I18n.t('draft.actions.manager_side_index.draft_successfully_completed'))
      window.location.href = state.draftManagerSide.draftsIndexUrl
    },
  })
}

export const draftGolfer = () => (dispatch, getState) => {
  dispatch({type: ActionTypes.SAVE_IN_PROGRESS})

  const state = getState()
  const url = state.draftManagerSide.draftGolferUrl
  const formData = new FormData()

  formData.append('team_id', state.draftManagerSide.nowPicking.teamId)
  formData.append('member_id', state.draftManagerSide.nowPicking.golfer.id)

  if (state.draftManagerSide.availableGolfers.length === 0) {
    formData.append('completed', 'true')
  }

  $.ajax({
    url: url,
    type: 'POST',
    data: formData,
    processData: false,
    contentType: false,
    success: (data) => {
      if (data.status === 'error') {
        showErrorNotification(window.I18n.t('draft.actions.manager_side_index.error_drafting_golfer'))
      } else {
        dispatch({type: ActionTypes.DRAFT_GOLFER})
      }
    },
    error: () => {
      showErrorNotification(window.I18n.t('draft.actions.manager_side_index.error_drafting_golfer'))
    },
  })
}

export const undoLastPick = () => (dispatch, getState) => {
  dispatch({type: ActionTypes.SAVE_IN_PROGRESS})

  const state = getState()
  const url = state.draftManagerSide.undoDraftGolferUrl
  const formData = new FormData()

  formData.append('team_id', state.draftManagerSide.lastPick.teamId)
  formData.append('member_id', state.draftManagerSide.lastPick.golfer.id)

  $.ajax({
    url: url,
    type: 'DELETE',
    data: formData,
    processData: false,
    contentType: false,
    success: (data) => {
      if (data.status === 'error') {
        showErrorNotification(window.I18n.t('draft.actions.manager_side_index.error_reverting_last_pick'))
      } else {
        dispatch({type: ActionTypes.UNDO_LAST_PICK})
      }
    },
    error: () => {
      showErrorNotification(window.I18n.t('draft.actions.manager_side_index.error_reverting_last_pick'))
    },
  })
}

export const onOkClicked = () => (dispatch, getState) => {
  dispatch({type: ActionTypes.SAVE_IN_PROGRESS})
  const state = getState()
  const url = state.draftManagerSide.draftCompletedUrl

  $.ajax({
    url: url,
    type: 'PUT',
    processData: false,
    contentType: false,
    success: () => {
      showNotification(window.I18n.t('draft.actions.manager_side_index.draft_successfully_completed'))
      window.location.href = state.draftManagerSide.draftsIndexUrl
    },
  })
}
