import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const getLuminance = (hexColor) => {
  let r = parseInt(hexColor.substring(1, 3), 16) / 255
  let g = parseInt(hexColor.substring(3, 5), 16) / 255
  let b = parseInt(hexColor.substring(5, 7), 16) / 255

  r = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4)
  g = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4)
  b = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4)

  return 0.2126 * r + 0.7152 * g + 0.0722 * b
}

const getFontColor = (hexColor) => {
  const luminance = getLuminance(hexColor)
  return luminance > 0.5 ? '#000' : '#FFF'
}

const getHoleClass = (par, score) => {
  const birdieScore = par
  const eagleScore = par - 1
  const plus1Score = par + 1

  if (score < eagleScore) {
    return 'double-circle'
  } else if (score < birdieScore && score >= eagleScore) {
    return 'simple-circle'
  } else if (score <= plus1Score && score > birdieScore) {
    return 'simple-square'
  } else if (score > plus1Score) {
    return 'double-square'
  }

  return ''  
}

const JrLeagueDashboard = ({ matchesResults }) => {
  const [ localMatchesResults, setLocalMatchesResults ] = useState(matchesResults)

  useEffect(() => {
    setLocalMatchesResults(matchesResults)
  }, [ matchesResults ])

  const loadMatches = () => {
    const matchKeys = Object.keys(localMatchesResults.matches)
    const numMatches = matchKeys.length / 2
    const parsArray = localMatchesResults.par_array
    const holes = localMatchesResults.holes

    return Array.from({ length: numMatches }).map((_, index) => {
      const team1Key = matchKeys[index * 2]
      const team2Key = matchKeys[index * 2 + 1]

      const team1Data = localMatchesResults.matches[team1Key]
      const team2Data = localMatchesResults.matches[team2Key]

      const team1Color = team1Data.color
      const team2Color = team2Data.color

      let team1FontColor = getFontColor(team1Color)
      let team2FontColor = getFontColor(team2Color)

      return (
        <React.Fragment key={index}>
          <tr>
            <td colSpan="1" className="match-label">MATCH {index + 1}</td>
            <td colSpan="13" className="match-cell"></td>
          </tr>

          <tr>
            <td>{team1Key}</td>
            {team1Data.events.map((event, eventIndex) => {
              const team1Points = event.points
              const team2Points = team2Data.events[eventIndex].points
              team1FontColor = getFontColor(team1Color)

              if (team1Points < team2Points) {
                team1FontColor = 'black'
              }

              const team1Style = {
                backgroundColor:
                  team1Points > team2Points || team1Points === team2Points ? team1Color : 'transparent',
                color:
                  team1Points > team2Points || team1Points === team2Points ? team1FontColor : 'black',
              }

              const team1ColorClass = {
                backgroundColor:
                  team1Color,
              }

              return (
                <React.Fragment key={eventIndex}>
                  {event.scores.slice(0, 3).map((score, scoreIndex) => {
                    const team2Score = team2Data.events[eventIndex].scores[scoreIndex]
                    const scoreStyle = score < team2Score ? team1ColorClass : {}
                    if (score < team2Score) {
                      team1FontColor = getFontColor(team1Color)
                    } else {
                      team1FontColor = 'black'
                    }
                    const parArray = holes === 'front9' 
                    ? parsArray.slice(0, 9)
                    : parsArray.slice(9, 18)
                    const par = parArray[scoreIndex * eventIndex]
                    const scoreClass = getHoleClass(par, score)
                    if (score === 0) {
                      score = null
                    }
                    return (
                      <td key={scoreIndex} style={scoreStyle}>{score !== null ? <span className={scoreClass} style={{ color: team1FontColor }}>{score}</span> : ''}</td>
                    )
                  })}
                  <td className='flag-separator' style={team1Style}>{team1Points}</td>
                </React.Fragment>
              )
            })}
          </tr>

          <tr>
            <td>{team2Key}</td>
            {team2Data.events.map((event, eventIndex) => {
              const team1Points = team1Data.events[eventIndex].points
              const team2Points = event.points
              team2FontColor = getFontColor(team2Color)

              const team2Style = {
                backgroundColor:
                  team2Points > team1Points || team2Points === team1Points ? team2Color : 'transparent',
                color:
                  team2Points > team1Points || team2Points === team1Points ? team2FontColor : 'black',
              }

              const team2ColorClass = {
                backgroundColor:
                  team2Color,
              } 

              return (
                <React.Fragment key={eventIndex}>
                  {event.scores.slice(0, 3).map((score, scoreIndex) => {
                    const team1Score = team1Data.events[eventIndex].scores[scoreIndex]
                    const scoreStyle = score < team1Score ? team2ColorClass : {}
                    if (score < team1Score) {
                      team2FontColor = getFontColor(team2Color)
                    } else {
                      team2FontColor = 'black'
                    }
                    const parArray = holes === 'front9' 
                    ? parsArray.slice(0, 9)
                    : parsArray.slice(9, 18)
                    const par = parArray[scoreIndex * eventIndex]
                    const scoreClass = getHoleClass(par, score)
                    
                    if (score === 0) {
                      score = null
                    }
                    return (
                      <td key={scoreIndex} style={scoreStyle}>{score !== null ? <span className={scoreClass} style={{ color: team2FontColor }}>{score}</span> : ''}</td>
                    )
                  })}
                  <td className='flag-separator' style={team2Style}>{team2Points}</td>
                </React.Fragment>
              )
            })}
          </tr>
        </React.Fragment>
      )
    })
  }

  if (!localMatchesResults || !localMatchesResults.teams || Object.keys(localMatchesResults.flags_totals).length === 0 || localMatchesResults.teams.length === 0) {
    return <div>Leaderboard is not created yet</div>
  }

  const { teams } = localMatchesResults
  const flags = localMatchesResults.flags
  const flagsTotals = localMatchesResults.flags_totals
  const holes = localMatchesResults.holes
  const parsArray = localMatchesResults.par_array
  
  return (
    <div className="jrleague-dashboard-root">
      <div className="scorecard">
        <div className="header centered-header">
          <div className="team-name" style={{ color: teams[0].color }}>
            {teams[0].name}
          </div>
          <div className="team-score">
            {teams[0].total_points}
          </div>
          <div className="team-score">
            {teams[1].total_points}
          </div>
          <div className="team-name" style={{ color: teams[1].color }}>
            {teams[1].name}
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th className="th-small"></th>
              {flags.map((flag, index) => (
                <th key={index} className={`flag-header flag-${index + 1}`} colSpan="4">
                  {flag}
                </th>
              ))}
            </tr>
            <tr>
              <th>Hole</th>
              {holes === 'front9'
                ? parsArray.slice(0, 9).map((_, holeIndex) => (
                    <React.Fragment key={holeIndex}>
                      <th className="hole">{holeIndex + 1}</th>
                      {(holeIndex + 1) % 3 === 0 && (
                        <th
                          className ="flag-separator"
                          style={{
                            backgroundColor: flagsTotals[Math.floor(holeIndex / 3)][teams[1].id].color,
                            color: getFontColor(flagsTotals[Math.floor(holeIndex / 3)][teams[1].id].color),
                          }}
                        >
                          {flagsTotals[Math.floor(holeIndex / 3)][teams[1].id].points}
                        </th>
                      )}
                    </React.Fragment>
                  ))
                : parsArray.slice(9, 18).map((_, holeIndex) => (
                    <React.Fragment key={holeIndex}>
                      <th className="hole">{holeIndex + 10}</th>
                      {(holeIndex + 1) % 3 === 0 && (
                        <th
                          className="flag-separator"
                          style={{
                            backgroundColor: flagsTotals[Math.floor(holeIndex / 3)][teams[1].id].color,
                            color: getFontColor(flagsTotals[Math.floor(holeIndex / 3)][teams[1].id].color),
                          }}
                        >
                          {flagsTotals[Math.floor(holeIndex / 3)][teams[1].id].points}
                        </th>
                      )}
                    </React.Fragment>
                  ))}
            </tr>
            <tr>
              <td className="par-label">Par</td>
              {holes === 'front9'
                ? parsArray.slice(0, 9).map((par, index) => (
                    <React.Fragment key={index}>
                      <td className="par">{par}</td>
                      {(index + 1) % 3 === 0 && (() => {
                        const eventIndex = Math.floor(index / 3)
                        const teamData = flagsTotals[eventIndex]?.[teams[0].id] || { color: '#FFFFFF', points: '' }
                        const fontColor = getFontColor(teamData.color)

                        return (
                          <td
                            className="flag-separator"
                            style={{
                              backgroundColor: teamData.color,
                              color: fontColor,
                            }}
                          >
                            {teamData.points}
                          </td>
                        )
                      })()}
                    </React.Fragment>
                  ))
                : parsArray.slice(9, 18).map((par, index) => (
                    <React.Fragment key={index}>
                      <td className="par">{par}</td>
                      {(index + 1) % 3 === 0 && (() => {
                        const eventIndex = Math.floor(index / 3)
                        const teamData = flagsTotals[eventIndex]?.[teams[0].id] || { color: '#FFFFFF', points: '' }
                        const fontColor = getFontColor(teamData.color)

                        return (
                          <td
                            className="flag-separator"
                            style={{
                              backgroundColor: teamData.color,
                              color: fontColor,
                            }}
                          >
                            {teamData.points}
                          </td>
                        )
                      })()}
                    </React.Fragment>
                  ))}
            </tr>
          </thead>
          <tbody>
            {loadMatches()}
          </tbody>
        </table>
      </div>
    </div>
  )
}

JrLeagueDashboard.propTypes = {
  matchesResults: PropTypes.object,
  parArray: PropTypes.array,
}

export default JrLeagueDashboard
