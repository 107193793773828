import React from 'react'
import rootReducer from '../reducers'
import PropTypes from 'prop-types'
import { GlobalProvider } from 'Shared/global_context'
import configureStore from 'Shared/configure_store'
import OttLayout from './ott_layout'

const OttPageRoot = ({ initialState }) => {
  const state = {
    data: initialState.data,
    selectedSlots: initialState.selected_slots,
    checkedSlots: [],
    leagueId: initialState.league_id,
    roundId: initialState.round_id,
    activeScreen: initialState.active_screen,
    minSlotsReg: initialState.min_slots_reg,
    maxSlotsReg: initialState.max_slots_reg,
    maxSlotsSignup: initialState.max_slots_signup,
    remainingSlotsSignupGuests: initialState.remaining_slots_signup_guests,
    remainingSlotsSignupMembers: initialState.remaining_slots_signup_members,
    remainingSlotsRegisterGolfers: initialState.remaining_slots_register_golfers,
    members: initialState.registered_members,
    currentMemberId: initialState.current_member_id,
    bookBeforeRegister: initialState.book_before_register,
    roundName: initialState.round_name,
    roundPlayAt: initialState.round_play_at,
    playersCount: initialState.foursome_count,
    selectablePlayers: initialState.selectable_players,
    disabledPlayers: initialState.disabled_players,
    currentUser: initialState.current_user,
    userInMasterRoster: initialState.user_in_master_roster,
    isPaymentsScenario: initialState.is_payments_scenario,
    isLoggedInWithGgid: initialState.is_logged_in_with_ggid,
    isAdminMode: initialState.is_in_admin_mode,
    isUpdateErrorPopupOpen: false,
    isRefreshQueuedUp: false,
    lastRefreshTime: new Date(),
    fullTeeSheet: initialState.full_tee_sheet,
    sessionStartTime: new Date(),
    isSwapModeOn: false,
    shortPollingEnablement: initialState.short_polling_enablement,
    eventOttEnabled: initialState.event_ott_enabled,
    twoHoursSinceRegistration: initialState.two_hours_since_registration,
    leagueName: initialState.league_name,
    useBayTerminology: initialState.use_bay_terminology,
    minMembersReg: initialState.min_members_reg,
    websiteId: initialState.website_id,
    unauthenticatedWithReserved: initialState.unauthenticated_with_reserved,
    pluckedPayments: initialState.plucked_payments,
    currency: initialState.currency,
    displayMobileScroller: true,
    slotsArray: initialState.slots_array,
    totalerTotal: initialState.totaler_total,
    totalerUnavailable: initialState.totaler_unavailable,
  }

  const store = configureStore(rootReducer, state, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

  return (
    <GlobalProvider store = { store }>
      <OttLayout />
    </GlobalProvider>
  )
}

OttPageRoot.propTypes = {
  initialState: PropTypes.any,
}

export default OttPageRoot

