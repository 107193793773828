export const ActionTypes = {
  SHOW_POPUP: 'SHOW_POPUP',
  ON_CLOSE: 'ON_CLOSE',
  DO_NOT_SHOW: 'DO_NOT_SHOW',
}

export const showPopup = (data) => (dispatch) => {
  dispatch(showPopupAction(data))
}

export const showPopupAction = (data) => ({
  type: ActionTypes.SHOW_POPUP,
  data,
})

export const onClose = () => (dispatch) => {
  dispatch(onCloseAction())
}

export const onCloseAction = () => ({
  type: ActionTypes.ON_CLOSE,
})

export const doNotShow = (checked) => (dispatch) => {
  dispatch(doNotShowAction(checked))
}

export const doNotShowAction = (checked) => ({
  type: ActionTypes.DO_NOT_SHOW,
  checked,
})