import React from 'react'
import PropTypes from 'prop-types'
import {Button, Modal} from 'react-bootstrap'

const LeaveDraftPopup = ({open, draftsIndexUrl, saveInProgress}) => {

  const onLeaveDraftClicked = () => {
    $('.leave_draft_popup .btsp_orange_button').attr('disabled', true)
      .text(window.I18n.t('buttons.redirecting'))
    window.location.href = draftsIndexUrl
  }

  return (
    <Modal className="draft_popup" show={open} popup="true">
      <Modal.Header>
        <Modal.Title className="text-center">
          {window.I18n.t('draft.components.manager_side.leave_draft_popup.draft_will_be_saved')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer className="popup_buttons">
        <Button type="button" className="btn button btsp_orange_button" disabled={saveInProgress}
                onClick={onLeaveDraftClicked}>
          {window.I18n.t('buttons.ok')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

LeaveDraftPopup.propTypes = {
  open: PropTypes.bool,
  draftsIndexUrl: PropTypes.string,
  saveInProgress: PropTypes.bool,
}

export default LeaveDraftPopup
